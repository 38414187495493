<template>
  <div class="manual" :class="{'manual--active':visible}">
    <div class="manual__trigger" @click="toggle">
      <button class="manual__close"><i class="icon-chevron-left"></i></button>
      <div>
        <template v-for="(letter, index) in name">
          <span :key="index">{{ letter }}</span>
        </template>
      </div>
    </div>
    <div class="manual__content">
      <button class="manual__close" @click="toggle"><i class="icon-chevron-left"></i></button>
      <h3 class="dark-red--text mt-2 mb-4">{{ content[step].text }}</h3>

      <v-list class="v-list--base">
        <v-list-item-group v-model="step" mandatory>
          <v-list-item
            v-for="(item, i) in content"
            :key="i"
            :disabled="item.disabled"
          >
            <v-list-item-content>
              <v-list-item-title v-text="item.text"></v-list-item-title>
            </v-list-item-content>
          </v-list-item>
        </v-list-item-group>
      </v-list>

    </div>
  </div>
</template>
<script>
export default {
  name: 'Manual',
  props: {
    step: {
      default: 0
    },
    visible: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {}
  },
  computed: {
    content() {
      return [
        {text: this.$t('instructions.step1'), disabled: true},
        {text: this.$t('instructions.step2'), disabled: true},
        {text: this.$t('instructions.step3'), disabled: true},
        {text: this.$t('instructions.step4'), disabled: true},
      ]
    },
    name() {
      return this.$t('instructions.name').split('');
    }
  },
  watch: {},
  mounted() {
    var _this = this;
    _this.content.map(function (el, index) {
      index >= _this.step ? el.disabled = false : el.disabled = true;
    });
  },
  methods: {
    toggle() {
      this.$emit('toggle');
    }
  },
}
</script>
