import Vue from 'vue';
import store from "../plugins/store";

export default {
    async login(data) {
        const response = await Vue.axios.post('/login', data);
        return response;
    },
    async logout() {
        const response = await Vue.axios.post('/logout');
        return response;
    },
    async register(data) {
        const response = await Vue.axios.post('api/v1/register', data);
        return response;
    },
    async verify() {
        const response = await Vue.axios.get('api/v1/me');
        await store.dispatch('user/setUser', response.data);
        Vue.prototype.$existingMember = response.data.main_member.status === 'waiting' || response.data.main_member.status === 'active';
        await store.dispatch('language/setLanguage', response.data.default_lang);
        return response;
    },
    async update(id, data) {
        const response = await Vue.axios.put('api/v1/member/' + id, data);
        return response;
    },
    async storeAddress(data) {
        const response = await Vue.axios.post('api/v1/member/address', data);
        return response;
    },
    async updateAddress(id, data) {
        const response = await Vue.axios.put('api/v1/member/address/' + id, data);
        return response;
    },
    async changePassword(data) {
        const response = await Vue.axios.put('api/v1/me/password', data);
        return response;
    }
}
