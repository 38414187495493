<template>
  <v-select
    v-bind:value="value"
    :item-text="getLabel"
    item-value="value"
    :label="$t('phone_number')"
    :items="phoneTypes"
    v-on:change="updateValue($event)"
    outlined
    attach
    hide-details
    :disabled="disabled"
  >
  </v-select>
</template>

<script>
import phoneTypes from "../../config/phoneTypes";

export default {
  name: "PhoneType",
  data: () => ({
    phoneTypes,
  }),
  props: ['value', 'disabled'],
  methods: {
    getLabel(item) {
      return this.$t(`phone_types.${item.value}`);
    },
    updateValue: function (value) {
      this.$emit('input', value);
      this.$emit('change');
    }
  }
}
</script>

<style scoped>

</style>
