import eligibilityService from "../services/eligibilityService";

const state = {
    eligibilities: null,
};

const getters = {
    eligibilities(state) {
        return state.eligibilities
    },
};

const mutations = {
    GET_DATA(state) {
        eligibilityService.index()
            .then(res => {
                state.eligibilities = res;
            });
    },
};

const actions = {};

export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations
};
