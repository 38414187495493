<template>
  <div>
    <v-main class="v-main--ip d-flex align-center" :class="[step != 0 ? 'fo' : '']">
      <div class="text-right mt-5" style="z-index: 999;">
        <v-btn reset class="v-btn mr-4" @click="changeLanguage()">
          <div class="d-flex flex-column">
            <v-icon size="28">icon-world</v-icon>
            <div style="font-size:14px; margin-top:5px;">Deutsch / Türkçe</div>
          </div>
        </v-btn>
      </div>
      <v-card class="v-card--login" :class="['step-'+step]">
        <v-card-text v-if="step == 0">

          <div class="welcomeSegment">
            <div class="welcomeSegment__image"><img src="../assets/images/logo-large.svg" alt=""></div>
            <div class="welcomeSegment__misc">
              <v-btn @click="step = 1">{{ $t('enter') }}</v-btn>
            </div>
          </div>

        </v-card-text>
        <v-card-text v-else-if="step == 1">

          <div class="text-center">
            <img src="../assets/images/logo.svg" alt="" style="width:80px;">
            <h1 class="dark-red--text mt-5">{{ $t('login') }}</h1>
            <h2 class="font-weight-regular mt-3">{{ $t('notAMemberYet') }}?
              <router-link :to="{name: 'Register'}" class="red--text">{{ $t('becomeAMember') }}.
              </router-link>
            </h2>
          </div>

          <v-form style="max-width:660px; margin:50px auto 0;">
            <v-row dense class="align-center row--br">
              <v-col style="max-width:190px;" class="text-right"><label>{{ $t('email') }}</label></v-col>
              <v-col class="pl-6">
                <v-text-field outlined hide-details :placeholder="'...' + $t('orMembershiNumber')"
                              v-on:keyup.enter="login"
                              v-model="user.email"></v-text-field>
              </v-col>
            </v-row>
            <v-row dense class="align-center row--br">
              <v-col style="max-width:190px;" class="text-right"><label>{{ $t('password') }}</label></v-col>
              <v-col class="pl-6">
                <v-text-field
                  v-on:keyup.enter="login"
                  :append-icon="loginShowPassword ? 'mdi-eye' : 'mdi-eye-off'"
                  @click:append="loginShowPassword = !loginShowPassword"
                  :type="loginShowPassword ? 'text' : 'password'"
                  outlined hide-details
                  :placeholder="$t('password')"
                  v-model="user.password"></v-text-field>
              </v-col>
            </v-row>
            <v-row dense class="align-center row--br">
              <v-col style="max-width:190px;" class="text-right"><label>{{ $t('stayLoggedIn') }}</label></v-col>
              <v-col class="pl-6">
                <div class="d-flex align-center">
                  <v-checkbox
                    :label="$t('stayLoggedIn')"
                    hide-details
                  ></v-checkbox>
                </div>
              </v-col>
            </v-row>
            <v-row dense class="align-center row--br">
              <v-col style="max-width:190px;" class="text-right"><label>{{ $t('needHelp') }}?</label></v-col>
              <v-col class="pl-6">
                <div class="d-flex align-center">
                  <div>
                    <a :href="'tel:' + contacts.phoneNumber" class="iconBlock">
                      <div class="iconBlock__icon">
                        <v-icon size="48" color="dark-red">icon-phone</v-icon>
                      </div>
                      <div class="iconBlock__text">{{ contacts.phoneNumber }}</div>
                    </a>
                  </div>
                  <div class="pl-12">
                    <a :href="'mailto:' + contacts.email" class="iconBlock">
                      <div class="iconBlock__icon">
                        <v-icon size="44" color="dark-red">icon-envelope</v-icon>
                      </div>
                      <div class="iconBlock__text">{{ contacts.email }}</div>
                    </a>
                  </div>
                </div>
              </v-col>
            </v-row>
            <div class="text-center mt-12">
              <v-btn @click="login">{{ $t('login') }}</v-btn>
              <br>
              <br>
              <a href="/forgot-password" class="grey--text ml-2">
                <b>{{ $t('forgotPassword') }}?</b>
              </a>
            </div>
          </v-form>

        </v-card-text>
      </v-card>
    </v-main>
  </div>
</template>
<script>
import userService from "../services/userService";
import contacts from "../config/contacts";

export default {
  name: 'Login',
  components: {},
  data: () => ({
    contacts,
    rules: {
      required: value => !!value || 'Required.',
      min: v => v.length >= 8 || 'Min 8 characters',
      max: v => v.length <= 51 || 'Max 50 characters',
      number: v => /[0-9]/.test(v) || 'At least one number',
      select: [(v) => v !== -1 || "Required"],
    },
    step: 1,
    loginShowPassword: false,
    showPassword: false,
    emailRequiredWindowShown: false,
    user: {
      email: '',
      password: ''
    },
  }),
  computed: {
    countries() {
      return this.$store.getters['countries/translatedCountries'];
    },
  },
  mounted() {
  },
  methods: {
    changeLanguage() {
      if (this.$i18n.locale === 'en') {
        this.$i18n.locale = 'de';
        return;
      }

      if (this.$i18n.locale === 'de') {
        this.$i18n.locale = 'tr';
        return;
      }

      this.$i18n.locale = 'en';
      return;
    },
    login() {
      userService.login({
        email: this.user.email.toLowerCase(),
        password: this.user.password
      }).then(() => {
        userService.verify().then(() => {
          this.$router.push({name: 'news'});
          this.resetVariables();
        });
      });
    },
    resetVariables() {
      this.user = {
        email: '',
        password: ''
      };
    },
  }
};
</script>
<style>
div.v-input--checkbox.error--text > div > div > div.v-input--selection-controls__input {
  border: 1px solid #ff5252;
}
</style>
