import Vue from 'vue';
import Vuetify from 'vuetify/lib/framework';

Vue.use(Vuetify);

export default new Vuetify({
    theme: {
        themes: {
            light: {
                primary: '#4d4d4d',
                red:'#ca1517',
                gray:'#8d999f',
                'light-gray':'#cccccc',
                'dark-red':'#97251c',
                'light-red':'#d45a51',
            },
        },
    },
});
