<template>
  <v-dialog
    :value="shown"
    :max-width="width"
    persistent
    content-class="v-dialog--confirm"
  >
    <v-card>
      <v-card-text>
        <p>{{ message }}</p>
      </v-card-text>
      <v-card-actions>
        <div>
          <v-btn @click="onConfirm">{{ $t('confirm') }}</v-btn>
          <v-btn class="ml-4"
                 @click="onReject">{{ $t('close') }}
          </v-btn>
        </div>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>
<script>
export default {
  name: "Confirm",
  components: {},
  props: {
    width: {
      default: 860
    },
  },
  data: () => ({
    message: '',
    resolve: null,
    reject: null,
    shown: false,
  }),
  computed: {},
  methods: {
    onConfirm() {
      if (this.resolve) {
        this.resolve();
        this.resolve = null;
      }
      this.shown = false;
    },
    onReject() {
      if (this.reject) {
        this.reject();
        this.reject = null;
      }
      this.shown = false;
    },
    confirm(message) {
      this.shown = true;
      this.message = message;
      return new Promise((resolve, reject) => {
        this.resolve = resolve;
        this.reject = reject;
      });
    }
  }
}
</script>

<style scoped>

</style>
