<template>
  <v-select
    :items="relationships"
    :rules="[rules.required]"
    v-bind:value="value"
    :item-text="getLabel"
    :disabled="disabled"
    item-value="name"
    :placeholder="$t('relationship')"
    :menu-props="{ bottom: true, offsetY: true, contentClass:'v-select-dropdown' }"
    v-on:change="updateValue($event)"
    hide-details
    attach
    outlined></v-select>
</template>

<script>
export default {
  name: "RelationshipType",
  data: () => ({
    rules: {
      required: value => !!value || 'Required.',
    },
  }),
  computed: {},
  props: {
    value: {
      type: String
    },
    eligibility: {
      type: String,
      default: null
    },
    disabled: {
      type: Boolean,
      default: false
    },
    relationships: {
      type: Array,
      default: () => {
        return [];
      }
    }
  },
  methods: {
    getLabel(item) {
      return this.$t(`relationship_types.${item.name}`);
    },
    updateValue: function (value) {
      this.$emit('input', value);
    }
  }
}
</script>

<style scoped>

</style>
