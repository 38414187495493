const states = [
    {
        label: 'Vorarlberg',
        value: 'Vorarlberg'
    },
    {
        label: 'Tirol',
        value: 'Tirol'
    },
    {
        label: 'Salzburg',
        value: 'Salzburg'
    },
    {
        label: 'Kärnten',
        value: 'Kärnten'
    },
    {
        label: 'Steiermark',
        value: 'Steiermark'
    },
    {
        label: 'Oberösterreich',
        value: 'Oberösterreich'
    },
    {
        label: 'Niederösterreich',
        value: 'Niederösterreich'
    },
    {
        label: 'Burgenland',
        value: 'Burgenland'
    },
    {
        label: 'Wien',
        value: 'Wien'
    },
];

export default states;
