<template>
  <v-select
    :items="genders"
    :rules="[rules.required]"
    v-bind:value="value"
    :item-text="getLabel"
    item-value="value"
    :placeholder="$t('gender')"
    :menu-props="{ bottom: true, offsetY: true, contentClass:'v-select-dropdown' }"
    :disabled="disabled"
    v-on:change="updateValue($event)"
    hide-details
    attach
    outlined>
  </v-select>
</template>

<script>
import genders from "../../config/genders";

export default {
  name: "Gender",
  data: () => ({
    genders,
    rules: {
      required: value => !!value || 'Required.',
    },
  }),
  props: {
    value: {
      type: String,
      default: null
    },
    disabled: {
      type: Boolean,
      default: false
    }
  },
  methods: {
    getLabel(item) {
      return this.$t(`genders.${item.label}`);
    },
    updateValue: function (value) {
      this.$emit('input', value);
      this.$emit('change', value);
    }
  }
}
</script>

<style scoped>

</style>
