import Vue from 'vue';

export default {
    async index() {
        const response = await Vue.axios.get('/api/v1/household/notifications');
        return response;
    },
    async store(id, data) {
        const response = await Vue.axios.post('/api/v1/household/' + id + '/notification', data);
        return response;
    },
    async markAsRead(id) {
        const response = await Vue.axios.put('/api/v1/household/notification/' + id + '/read');
        return response;
    }
}
