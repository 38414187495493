<template>
  <Dashboard class="dashboard--news">
    <div class="card" v-if="selectedSubstitutes">
      <div>
        <v-form style="max-width:760px;" ref="substitute">
          <v-row dense class="align-center row--br">
            <v-col style="max-width:200px;" class="text-right">&nbsp;</v-col>
            <v-col class="pl-6">
              <div class="d-flex align-center justify-space-between">
                <h4 class="dark-red--text ma-0">{{ $t('personal_data') }}</h4>
              </div>
            </v-col>
          </v-row>
          <v-row dense class="align-center row--br">
            <v-col style="max-width:200px;" class="text-right"><label>{{ $t('first_name') }}</label></v-col>
            <v-col class="pl-6">
              <v-text-field outlined :placeholder="$t('first_name')"
                            @change="isDirty = true"
                            v-model="selectedSubstitutes.first_name" :rules="[rules.required]"></v-text-field>
            </v-col>
          </v-row>
          <v-row dense class="align-center row--br">
            <v-col style="max-width:200px;" class="text-right"><label>{{ $t('last_name') }}</label></v-col>
            <v-col class="pl-6">
              <v-text-field outlined :placeholder="$t('last_name')"
                            @change="isDirty = true"
                            v-model="selectedSubstitutes.last_name" :rules="[rules.required]"></v-text-field>
            </v-col>
          </v-row>

          <v-row dense class="align-center row--br">
            <v-col style="max-width:200px;" class="text-right">&nbsp;</v-col>
            <v-col class="pl-6">
              <div class="d-flex align-center justify-space-between">
                <h4 class="dark-red--text ma-0">{{ $t('contact_details') }}</h4>
              </div>
            </v-col>
          </v-row>

          <v-row dense class="align-center row--br">
            <v-col style="max-width:200px;" class="text-right"><label>{{ $t('phone_types.private_mobile') }}</label>
            </v-col>
            <v-col class="pl-6">
              <v-text-field outlined
                            v-model="selectedSubstitutes.phone_private_mobile"
                            @change="isDirty = true"
                            :rules="[rules.required, rules.phone_number, rules.min, rules.max]"></v-text-field>
            </v-col>
          </v-row>
          <v-row dense class="align-center row--br">
            <v-col style="max-width:200px;" class="text-right"><label>{{ $t('phone_types.private_landline') }}</label>
            </v-col>
            <v-col class="pl-6">
              <v-text-field outlined
                            :rules="validatePhoneLandline"
                            @change="isDirty = true"
                            v-model="selectedSubstitutes.phone_private_landline"></v-text-field>
            </v-col>
          </v-row>
          <v-row dense class="align-center row--br">
            <v-col style="max-width:200px;" class="text-right"><label>{{ $t('email') }}</label></v-col>
            <v-col class="pl-6">
              <v-text-field outlined :placeholder="$t('email')"
                            @change="isDirty = true"
                            v-model="selectedSubstitutes.email" :rules="[rules.required, rules.email]"></v-text-field>
            </v-col>
          </v-row>

          <v-row dense class="align-center row--br">
            <v-col style="max-width:200px;" class="text-right">&nbsp;</v-col>
            <v-col class="pl-6">
              <div class="d-flex align-center justify-space-between">
                <h4 class="dark-red--text ma-0">{{ $t('substitutes.substitutes') }}</h4>
              </div>
            </v-col>
          </v-row>
          <v-row dense class="align-center row--br">
            <v-col style="max-width:200px;" class="text-right"><label>{{ $t('substitutes.substitute_as') }}</label>
            </v-col>
            <v-col class="pl-6">
              <substitute-type v-model="selectedSubstitutes.represent_me_as"
                               @change="isDirty = true"
                               :rules="[rules.required]"></substitute-type>
            </v-col>
          </v-row>
          <v-row dense class="align-center row--br">
            <v-col style="max-width:200px;" class="text-right"><label>{{ $t('substitutes.valid_till') }}</label></v-col>
            <v-col class="pl-6">
              <date-picker
                :min="today"
                v-model="selectedSubstitutes.valid_until"
                :placeholder="$t('substitutes.valid_till')"></date-picker>
              <!--              <v-menu-->
              <!--                @change="isDirty = true"-->
              <!--                v-model="datePicker1"-->
              <!--                :close-on-content-click="false"-->
              <!--                :nudge-right="0"-->
              <!--                transition="scale-transition"-->
              <!--                offset-y-->
              <!--                min-width="280px"-->
              <!--                attach=".col"-->
              <!--                :rules="[rules.required]"-->
              <!--              >-->
              <!--                <template v-slot:activator="{ on, attrs }">-->
              <!--                  <v-text-field-->
              <!--                    outlined-->
              <!--                    :rules="[rules.required, validateValidUntill]"-->
              <!--                    :placeholder="$t('substitutes.valid_till')"-->
              <!--                    v-model="selectedSubstitutes.valid_until"-->
              <!--                    append-icon="icon-calendar"-->
              <!--                    readonly-->
              <!--                    v-bind="attrs"-->
              <!--                    v-on="on"-->
              <!--                    @input="dateInput"-->
              <!--                    class="v-input&#45;&#45;datepicker"-->
              <!--                  ></v-text-field>-->
              <!--                </template>-->
              <!--                <v-date-picker-->
              <!--                  v-model="selectedSubstitutes.valid_until"-->
              <!--                  :min="today"-->
              <!--                  @input="datePicker1 = false"-->
              <!--                  color="red"-->
              <!--                ></v-date-picker>-->
              <!--              </v-menu>-->
            </v-col>
          </v-row>
          <v-row dense class="align-center row--br">
            <v-col style="max-width:200px;" class="text-right">&nbsp;</v-col>
            <v-col class="pl-6">
              <v-btn text reset class="mr-4" @click="closeEditing">
                <span class="red--text">{{ $t('close') }}</span>
              </v-btn>
              <v-btn v-if="selectedSubstitutes.id !== -1" rounded class="mt-1" @click="updateRepresentation">
                <v-icon>icon-user</v-icon>
                <span>{{ $t('update') }}</span>
              </v-btn>
              <v-btn v-else rounded class="mt-1" @click="storeRepresentation">
                <v-icon>icon-user</v-icon>
                <span>{{ $t('save') }}</span>
              </v-btn>
            </v-col>
          </v-row>

        </v-form>

        <!-------------------------->
      </div>
    </div>
    <confirm ref="confirm"></confirm>
  </Dashboard>
</template>
<script>
import Dashboard from '@/layouts/Dashboard.vue';
import moment from "moment";
import substituteTypes from "../../config/substituteTypes";
import substituteService from "../../services/substituteService";
import userService from "../../services/userService";
import SubstituteType from "../../components/Fields/SubstituteType";
import Confirm from "../../components/Dialogs/Confirm";
import DatePicker from "../../components/Fields/DatePicker";

export default {
  name: 'RepresentationsNew',
  components: {
    Confirm,
    Dashboard,
    SubstituteType,
    DatePicker
  },
  data: () => ({
    substituteTypes,
    rules: {
      required: value => !!value || 'Required.',
      email: value => !value || /^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,3})+$/.test(value) || 'Invalid E-mail address',
      phone_number: value => !value || /^\+?[0-9]+$/.test(value) || 'Invalid phone number',
      min: v => v.length >= 6 || 'Min 6 characters',
      max: v => v.length <= 15 || 'Max 15 characters',
    },
    date: null,
    datePicker1: false,

    collapse: false,
    editUser: false,

    deleteWindowShown: false,

    selectedSubstitutes: null,

    newSubstitute: {
      id: -1,
      first_name: '',
      last_name: '',
      email: null,
      phone_private_landline: '',
      phone_private_mobile: '',
      phone_numbers: [],
      represent_me_as: 'lawyer',
      valid_until: null,
    },
    today: new Date().toJSON(),
    isDirty: false
  }),
  computed: {
    user() {
      return this.$store.getters['user/user'];
    },
    validatePhoneLandline() {
      return [
        (v) => {
          if (v === null) {
            return true;
          }

          if (v.length === 0) {
            return true;
          }

          if (!/^\+?[0-9]+$/.test(v)) {
            return 'Invalid phone number';
          }

          if (v.length < 6) {
            return 'Min 6 characters';
          }

          if (v.length > 15) {
            return 'Max 15 characters';
          }

          return true;
        }
      ];
    }
  },
  mounted() {
    if (this.$route.params.id !== undefined && !isNaN(parseInt(this.$route.params.id))) {
      this.selectedSubstitutes = JSON.parse(JSON.stringify(this.user.substitutes
        .find(item => item.id === parseInt(this.$route.params.id))
      ));
      return;
    }
    this.addRepresentation();
  },
  methods: {
    closeEditing() {
      this.$router.push({name: 'substitutes'});
    },
    addRepresentation() {
      this.selectedSubstitutes = JSON.parse(JSON.stringify(this.newSubstitute));
    },
    storeRepresentation() {
      if (!this.$refs.substitute.validate()) {
        return;
      }
      let data = this.selectedSubstitutes;
      data.valid_until = moment(this.selectedSubstitutes.valid_until).format('DD.MM.YYYY');
      substituteService.store(this.user.household.id, data)
        .then(() => {
          userService.verify().then(() => {
            this.isDirty = false;
            this.selectedSubstitutes = null;
            this.$router.push({name: 'substitutes'});
          });
        });
    },
    updateRepresentation() {
      if (!this.$refs.substitute.validate()) {
        return;
      }

      let data = this.selectedSubstitutes;
      data.valid_until = moment(this.selectedSubstitutes.valid_until).format('DD.MM.YYYY');
      substituteService.update(this.selectedSubstitutes.id, data)
        .then(() => {
          userService.verify().then(() => {
            this.isDirty = false;
            this.selectedSubstitutes = null;
            this.$router.push({name: 'substitutes'});
          });
        });
    },
    dateInput(val) {
      if (val.length === 4 || val.length === 7) {
        this.selectedSubstitutes.valid_until = val + '-';
      }
      if (val.length > 10) {
        this.selectedSubstitutes.valid_until = val.slice(0, -2);
      }
      return false;
    },
    validateValidUntill(value) {
      if (value === null || value === '' || value.length < 10) {
        return false;
      }

      if (moment(value, 'YYYY-MM-DD').isAfter(moment())) {
        return true;
      }

      return false;
    },
  },
  beforeRouteLeave(to, from, next) {
    if (!this.isDirty) {
      this.selectedSubstitutes = null;
      next();
      return;
    }

    this.$refs.confirm.confirm(this.$t('confirmUnsavedDataProceed'))
      .then(next)
      .catch(() => {
        next(false);
      });
  }
};
</script>
