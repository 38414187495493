import i18n from "../plugins/i18n";

const state = {
    countries: []
};

const getters = {
    countries(state) {
        return state.countries
    },
    translatedCountries(state) {
        return state.countries.map(country => {
            return {
                id: country.id,
                text: country.translations.find(trans => trans.locale === i18n.locale).name
            }
        });
    }
};

const mutations = {
    setCountries(state, value) {
        state.countries = value;
    },
};

const actions = {
    setCountries(context, value) {
        context.commit('setCountries', value);
    },
};

export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations
};
