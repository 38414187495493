<template>
  <header class="header">
    <div>

      <div class="iconBlock navToggle" data-mobile @click="sidebarToggle">
        <div class="iconBlock__icon">
          <div class="iconSq gradient gradient--black">
            <div></div>
          </div>
        </div>
      </div>
      <div class="iconBlock logo">
        <div class="iconBlock__icon">
          <div class="iconSq gradient gradient--black">
            <v-btn to="/news">
              <img src="@/assets/images/logo-text.svg" style="max-width:25px;" alt="">
            </v-btn>
          </div>
        </div>
        <div class="iconBlock__text">
          <strong style="font-size:21px;">{{ $t('memberPortal') }}</strong>
        </div>
      </div>
    </div>
    <div>

      <v-btn reset class="v-btn--language mr-4" @click="changeLanguage()">
        <div class="d-flex flex-column">
          <v-icon size="28">icon-world</v-icon>
          <div style="font-size:14px; margin-top:5px;">Deutsch / Türkçe</div>
        </div>
      </v-btn>
      <v-menu offset-y content-class="v-dropdown--red">
        <template v-slot:activator="{ on, attrs }">
          <v-btn
            v-bind="attrs"
            v-on="on"
            color="primary"
            class="v-btn--userMenu mr-n5"
          >
            <div class="iconBlock">
              <div class="iconBlock__text" style="padding-right:10px;">
                <strong>{{ user.name }}</strong>
              </div>
              <div class="iconBlock__icon">
                <div class="iconSq gradient gradient--black">
                  <v-icon>icon-user</v-icon>
                </div>
              </div>
            </div>

          </v-btn>
        </template>
        <v-list>
          <v-list-item
            v-for="(item, index) in userMenu"
            :key="index"
            @click="menuClicked(item)"
          >
            {{ item.title }}
          </v-list-item>
        </v-list>
      </v-menu>

    </div>
  </header>
</template>
<script>
import userService from "../services/userService";
import {languages} from "../enumerations/languages";
import store from "../plugins/store";

export default {
  name: 'Header',
  data: () => ({
    bodyClassName: 'sidebar--show'
  }),
  mounted() {
    this.sidebarHide();
  },
  computed: {
    userMenu() {
      return [
        {title: this.$t('header.my_account'), path: '/my-account', value: 'my-account'},
        // {title: 'Deutsch / Türkçe', path: '/', value: 'language'},
        {title: this.$t('header.logout'), path: '/', value: 'logout'},
      ]
    },
    user() {
      return this.$store.getters['user/user'];
    },
  },
  methods: {
    changeLanguage() {
      const keys = Object.keys(languages);
      const index = keys.indexOf(this.$i18n.locale.toUpperCase());
      const language = keys[(index + 1) % keys.length];

      store.dispatch('language/changeLanguage', languages[language]);
    },
    menuClicked(item) {
      if (item.value === 'my-account') {
        this.$router.push({name: 'my-account'});
        return;
      }

      if (item.value === 'logout') {
        userService.logout().then(() => {
          this.$store.dispatch('user/logout').then(() => {
            this.$router.push({name: 'Login'});
          });
        });
        return;
      }
    },
    sidebarHide() {
      var body = document.body,
        bodyClass = document.getElementsByClassName(this.bodyClassName);
      if (bodyClass.length > 0) body.classList.remove(this.bodyClassName);
    },
    sidebarToggle() {
      var body = document.body,
        bodyClass = document.getElementsByClassName(this.bodyClassName);

      bodyClass.length > 0 ? body.classList.remove(this.bodyClassName) : body.classList.add(this.bodyClassName);
    }
  }
};
</script>
