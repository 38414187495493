<template>
  <Dashboard>
    <div class="card">
      <div>
        <v-data-table
          :headers="tableHeaders"
          :items="user.substitutes"
          :items-per-page="-1"
          :hide-default-footer="true"
          :sort-by="['created_at']"
          :sort-desc="false"
          class="v-data-table--collapse"
          :class="{'collapse':collapse}"
        >
          <template>
            <v-btn icon style="width:40px!important; height:40px!important; min-width:40px!important;"
                   @click="tableCollapse">
              <v-icon>icon-chevron-up</v-icon>
            </v-btn>
          </template>
          <template v-slot:item.status="{item}">
            <v-badge :color="badgeColor(item.status)">
              <span slot="badge">{{ item.status.toUpperCase() }}</span>
            </v-badge>
          </template>
          <template v-slot:item.first_name="{item}">
            <div class="d-flex align-center">
              <div class="flex-grow-1">{{ item.first_name }}</div>
            </div>
          </template>
          <template v-slot:item.last_name="{item}">
            <div class="d-flex align-center">
              <div class="flex-grow-1">{{ item.last_name }}</div>
            </div>
          </template>
          <template v-slot:item.valid_until="{item}">
            <div class="d-flex align-center">
              <div class="flex-grow-1">{{ item.valid_until }}</div>
            </div>
          </template>
          <template v-slot:item.created_at="{item}">
            {{ item.created_at | moment }}
          </template>
          <template v-slot:item.actions="{item}">
            <div class="d-flex align-center">
              <div class="d-flex">
                <v-tooltip bottom>
                  <template v-slot:activator="{ on, attrs }">
                    <v-btn icon reset v-bind="attrs" v-on="on" class="ml-4" @click="substituteBeEdited(item)">
                      <v-icon color="red" size="22">icon-edit</v-icon>
                    </v-btn>
                  </template>
                  <span>{{ $t('change') }}</span>
                </v-tooltip>
                <!--                <v-tooltip bottom>-->
                <!--                  <template v-slot:activator="{ on, attrs }">-->
                <!--                    <v-btn icon reset v-bind="attrs" v-on="on" class="ml-4" @click="substituteBeDeleted(item)">-->
                <!--                      <v-icon color="red" size="22">icon-trash</v-icon>-->
                <!--                    </v-btn>-->
                <!--                  </template>-->
                <!--                  <span>{{ $t('delete') }}</span>-->
                <!--                </v-tooltip>-->
              </div>
            </div>
          </template>
        </v-data-table>
        <v-btn rounded class="mt-5" @click="addRepresentation">
          <v-icon>icon-user</v-icon>
          <span>{{ $t('add_representation') }}</span>
        </v-btn>
        <!-------------------------->
      </div>
    </div>
    <div class="buttonContainer justify-space-between mt-3">
      <v-btn to="/sub-member">
        <div class="iconSq iconSq--sm iconSq--border gradient">
          <v-icon color="white" size="22">icon-chevron-left</v-icon>
        </div>
        <div class="flex-grow-1 text-center">{{ $t('navigationButtons.backToSubmembers') }}</div>
      </v-btn>
      <v-btn to="/payment" style="min-width:500px!important;">
        <div class="flex-grow-1 text-center">{{ $t('navigationButtons.nextToPayments') }}</div>
        <div class="iconSq iconSq--sm iconSq--border gradient" style="margin-left:auto; margin-right:-14px;">
          <v-icon color="white" size="22">icon-chevron-right</v-icon>
        </div>
      </v-btn>
    </div>

    <!---------------------------------------------------------->

    <app-dialog :visible.sync="deleteWindowShown" type="alert">
      <template v-slot:body>
        <div class="text-center">
          <h3 class="dark-red--text">{{ $t('delete') }} ?</h3>
          <p class="mt-5">
            {{ $t('confirmAction') }}</p>
        </div>
      </template>
      <template v-slot:actions>
        <v-btn @click="deleteRepresentation" color="green"><strong>{{ $t('confirm') }}</strong></v-btn>
        <v-btn @click="deleteWindowShown = false;"><strong>{{ $t('cancel') }}</strong></v-btn>
      </template>
    </app-dialog>
    <confirm ref="confirm"></confirm>
  </Dashboard>
</template>
<script>
import Dashboard from '@/layouts/Dashboard.vue';
import AppDialog from '@/components/Dialog.vue';
import moment from "moment";
import substituteService from "../../services/substituteService";
import userService from "../../services/userService";
import Confirm from "../../components/Dialogs/Confirm";

export default {
  name: 'Representations',
  components: {
    Confirm,
    Dashboard,
    AppDialog,
  },
  data: () => ({
    date: null,
    collapse: false,
    deleteWindowShown: false,
    selectedSubstitutes: null,
  }),
  computed: {
    tableHeaders() {
      return [
        {
          sortable: false,
          value: 'collapse',
          width: '80px'
        },
        {text: this.$t('status'), value: 'status'},
        {text: this.$t('first_name'), value: 'first_name'},
        {text: this.$t('last_name'), value: 'last_name'},
        {text: this.$t('substitutes.valid_till'), value: 'valid_until'},
        {text: this.$t('entry_date'), value: 'created_at'},
        {text: this.$t('actions'), value: 'actions', sortable: false, width: '100px'},
      ];
    },
    user() {
      return this.$store.getters['user/user'];
    },
  },
  filters: {
    moment(date) {
      if (date === null) {
        return null;
      }
      return moment(date).format('D.M.Y');
    }
  },
  mounted() {
  },
  methods: {
    onOpen(i) {
      var _this = this;
      _this.emailData[i].seen = true;
    },
    tableCollapse() {
      this.collapse = !this.collapse;
    },
    substituteBeDeleted(member) {
      this.selectedSubstitutes = member;
      this.deleteWindowShown = true;
    },
    substituteBeEdited(item) {
      this.$router.push({name: 'substitutesEdit', params: {id: item.id}});
      return;
    },
    addRepresentation() {
      this.$router.push({name: 'substitutesAdd'});
    },
    deleteRepresentation() {
      substituteService.delete(this.selectedSubstitutes.id)
        .then(() => {
          this.deleteWindowShown = false;
          this.selectedSubstitutes = null;
          userService.verify();
        });
    },
    badgeColor(status) {
      if (status === 'active') {
        return 'success';
      }

      return 'red';
    },
  },
};
</script>
