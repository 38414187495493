import Vue from 'vue';

export default {
    async store(memberId, data) {
        const response = await Vue.axios.post('/api/v1/member/' + memberId + '/phone', data);
        return response;
    },
    async update(id, data) {
        const response = await Vue.axios.put('/api/v1/member/phone/' + id, data);
        return response;
    },
    async delete(id) {
        const response = await Vue.axios.delete('/api/v1/member/phone/' + id);
        return response;
    },
}
