<template>
  <div>
    <v-main class="v-main--ip d-flex align-center fo">
      <div class="text-right mt-5" style="z-index: 999;">
        <v-btn reset class="v-btn mr-4" @click="changeLanguage()">
          <div class="d-flex flex-column">
            <v-icon size="28">icon-world</v-icon>
            <div style="font-size:14px; margin-top:5px;">Deutsch / Türkçe</div>
          </div>
        </v-btn>
      </div>
      <v-card class="v-card--login step-2">
        <v-card-text>
          <div class="text-center">
            <img src="../assets/images/logo.svg" alt="" style="width:80px;">
            <h1 class="dark-red--text mt-5">{{ $t('registerPage.wantToBecomeAMember') }}</h1>
            <h2 class="font-weight-regular mt-3">{{ $t('registerPage.alreadyMember') }}
              <router-link class="red--text" :to="{name: 'Login'}">{{ $t('registerPage.login') }}</router-link>
            </h2>
          </div>

          <v-form style="margin-top:50px;" ref="registration">
            <v-row class="row--border row--br">
              <v-col>

                <v-row dense class="align-center row--br">
                  <v-col style="max-width:190px;" class="text-right"><label>{{ $t('registerPage.firstName') }}</label>
                  </v-col>
                  <v-col class="pl-6">
                    <v-text-field :rules="[rules.required]"
                                  outlined
                                  :placeholder="$t('registerPage.firstName') + '*'"
                                  v-model="newUser.first_name"></v-text-field>
                  </v-col>
                </v-row>
                <v-row dense class="align-center row--br">
                  <v-col style="max-width:190px;" class="text-right"><label>{{ $t('registerPage.lastName') }}</label>
                  </v-col>
                  <v-col class="pl-6">
                    <v-text-field :rules="[rules.required]"
                                  outlined
                                  :placeholder="$t('registerPage.lastName') + '*'"
                                  v-model="newUser.last_name"></v-text-field>
                  </v-col>
                </v-row>
                <v-row dense class="align-center row--br">
                  <v-col style="max-width:190px;" class="text-right"><label>{{ $t('registerPage.email') }}</label>
                  </v-col>
                  <v-col class="pl-6">
                    <v-text-field :rules="[rules.required]"
                                  outlined
                                  :placeholder="$t('registerPage.email') + '*'"
                                  v-model="newUser.email"></v-text-field>
                  </v-col>
                </v-row>
                <v-row dense class="align-center row--br">
                  <v-col style="max-width:190px;" class="text-right"><label>{{ $t('registerPage.phoneNumber') }}</label>
                  </v-col>
                  <v-col class="pl-6">
                    <v-text-field :rules="[rules.required]"
                                  outlined
                                  :placeholder="$t('registerPage.phoneNumber') + '*'"
                                  v-model="newUser.phoneNumber"></v-text-field>
                  </v-col>
                </v-row>
                <v-row dense class="align-center row--br">
                  <v-col style="max-width:190px;" class="text-right"><label>{{ $t('registerPage.password') }}</label>
                  </v-col>
                  <v-col class="pl-6">
                    <v-text-field
                      class="input-details-abs"
                      :append-icon="showPassword ? 'mdi-eye' : 'mdi-eye-off'"
                      @click:append="showPassword = !showPassword"
                      :type="showPassword ? 'text' : 'password'"
                      :rules="[rules.required, rules.min, rules.max, rules.upperCase, rules.lowerCase, rules.number]"
                      counter
                      outlined
                      :placeholder="$t('registerPage.password') + '*'"
                      v-model="newUser.password">
                    </v-text-field>
                  </v-col>
                </v-row>
                <v-row dense class="align-center row--br">
                  <v-col style="max-width:190px;" class="text-right">
                    <label>{{ $t('registerPage.confirmPassword') }}</label>
                  </v-col>
                  <v-col class="pl-6">
                    <v-text-field
                      class="input-group--focused input-details-abs"
                      :append-icon="showConfirmation ? 'mdi-eye' : 'mdi-eye-off'"
                      @click:append="showConfirmation = !showConfirmation"
                      :type="showConfirmation ? 'text' : 'password'"
                      :rules="[rules.required, rules.min, rules.max, passwordConfirmationRule]"
                      counter
                      outlined
                      :placeholder="$t('registerPage.confirmPassword') + '*'"
                      v-model="newUser.password_confirmation"></v-text-field>
                  </v-col>
                </v-row>
              </v-col>
              <v-col style="max-width:480px;">

                <v-row dense class="align-center row--br">
                  <v-col style="flex:none; width:auto;">
                    <label>{{ $t('registerPage.nationality') }}</label>
                  </v-col>
                  <v-col class="pl-6">
                    <v-select
                      :items="countries"
                      v-model="newUser.citizenship"
                      return-object
                      :placeholder="$t('citizenship')"
                      item-text="text"
                      item-value="id"
                      :menu-props="{ bottom: true, offsetY: true, contentClass:'v-select-dropdown' }"
                      outlined
                      attach
                      required
                      :rules="rules.select"
                    ></v-select>
                  </v-col>
                </v-row>

                <div class="mt-6">
                  <v-checkbox
                    :rules="[rules.checkboxRequired]"
                    v-model="newUser.pernament_residence"
                    :label="$t('registerPage.confirmPernamentResidence')"
                    class="cb--inv cb--text-sm"
                  ></v-checkbox>

                  <div class="d-flex mt-10">
                    <div class="pt-3"><label>{{ $t('needHelp') }}?</label></div>
                    <div class="pl-6">
                      <div>
                        <a :href="'tel:' + contacts.phoneNumber" class="iconBlock">
                          <div class="iconBlock__icon">
                            <v-icon size="50" color="dark-red">icon-phone</v-icon>
                          </div>
                          <div class="iconBlock__text">{{ contacts.phoneNumber }}</div>
                        </a>
                      </div>
                      <div class="mt-2">
                        <a :href="'mailto:' + contacts.email" class="iconBlock">
                          <div class="iconBlock__icon">
                            <v-icon size="48" color="dark-red">icon-envelope</v-icon>
                          </div>
                          <div class="iconBlock__text">{{ contacts.email }}</div>
                        </a>
                      </div>
                    </div>
                  </div>

                </div>

              </v-col>
            </v-row>

            <div style="font-size:18px; max-width:810px; margin:40px auto;">
              <p>
                * {{ $t('registerPage.mandatoryField') }}<br>
                {{ $t('registerPage.password_info') }}<br>
                {{ $t('registerPage.personalDataUsageConsent') }}
                <a class="red--text" @click.prevent="downloadTermsOfUse">{{ $t('termsOfUse') }}</a>
                {{ $t('registerPage.contentBetweeenAgreement') }}
                <a class="red--text" @click.prevent="downloadDataProtectionDoc">
                  {{ $t('dataProtectionAgreement') }}
                </a>
                {{ $t('registerPage.contentAfterAgreement') }}
              </p>
            </div>
            <div class="text-center">
              <v-btn @click="register">{{ $t('registerPage.wantToBecomeAMember') }}</v-btn>
            </div>
          </v-form>
        </v-card-text>
      </v-card>

      <app-dialog :visible.sync="showAlertWindow" type="alert">
        <template v-slot:body>
          <div class="text-center">
            <h3 class="dark-red--text">ERROR</h3>
            <p class="mt-5">{{ alertText }}</p>
          </div>
        </template>
        <template v-slot:actions>
          <v-btn @click="showAlertWindow = false;"><strong>{{ $t('close') }}</strong></v-btn>
        </template>
      </app-dialog>
    </v-main>
  </div>
</template>
<script>
import AppDialog from '@/components/Dialog.vue';
import userService from "../services/userService";
import contacts from "../config/contacts";
import agreementService from "../services/agreementService";

export default {
  name: 'Login',
  components: {
    AppDialog,
  },
  data: () => ({
    contacts,
    rules: {
      required: value => !!value || 'Required.',
      checkboxRequired: value => value || 'Required',
      min: v => v.length >= 8 || 'Min 8 characters',
      max: v => v.length <= 51 || 'Max 50 characters',
      upperCase: v => /[A-Z]/.test(v) || 'At least one uppercase',
      lowerCase: v => /[a-z]/.test(v) || 'At least one lowercase',
      number: v => /[0-9]/.test(v) || 'At least one number',
      select: [(v) => v !== -1 || "Required"],
    },
    step: 2,
    showPassword: false,
    showConfirmation: false,
    showAlertWindow: false,
    alertText: '',
    newUser: {
      first_name: '',
      last_name: '',
      citizenship: -1,
      email: '',
      phoneNumber: '',
      password: '',
      password_confirmation: '',
      pernament_residence: false
    },
  }),
  computed: {
    passwordConfirmationRule() {
      return () => (this.newUser.password === this.newUser.password_confirmation) || 'Password must match'
    },
    countries() {
      return this.$store.getters['countries/translatedCountries'];
    },
  },
  mounted() {
  },
  methods: {
    changeLanguage() {
      if (this.$i18n.locale === 'en') {
        this.$i18n.locale = 'de';
        return;
      }

      if (this.$i18n.locale === 'de') {
        this.$i18n.locale = 'tr';
        return;
      }

      this.$i18n.locale = 'en';
      return;
    },
    register() {
      if (!this.$refs.registration.validate()) {
        return;
      }

      if (this.newUser.password !== this.newUser.password_confirmation) {
        this.showAlertWindow = true;
        this.alertText = 'Incorect password!';
        return;
      }

      if (this.newUser.citizenship === null) {
        this.showAlertWindow = true;
        this.alertText = 'Select nationality!';
        return;
      }

      userService.register({
        "first_name": this.newUser.first_name,
        "last_name": this.newUser.last_name,
        "citizenship_id": this.newUser.citizenship.id,
        "email": this.newUser.email,
        "password": this.newUser.password,
        "phone_number": this.newUser.phoneNumber,
        "phone_type": "private_mobile",
        "permanent_residence": this.newUser.pernament_residence,
        "language": this.$i18n.locale
      }).then(() => {
        this.$store.commit('registerInstruction/toggleShow', true);
        this.resetVariables();
        this.$router.push({name: 'Login'});
        return;
      });
    },
    resetVariables() {
      this.newUser = {
        first_name: '',
        last_name: '',
        citizenship: -1,
        email: '',
        phoneNumber: '',
        password: '',
        password_confirmation: '',
        pernament_residence: false
      };
    },
    downloadTermsOfUse() {
      agreementService.termsOfUse()
        .then(res => {
          var blob = new Blob([res]);
          var link = document.createElement('a');
          link.href = window.URL.createObjectURL(blob);
          link.download = this.$t('termsOfUse') + '.pdf';
          link.click();
        });
    },
    downloadDataProtectionDoc() {
      agreementService.dataProtection()
        .then(res => {
          var blob = new Blob([res]);
          var link = document.createElement('a');
          link.href = window.URL.createObjectURL(blob);
          link.download = this.$t('dataProtectionAgreement') + '.pdf';
          link.click();
        });
    }
  }
};
</script>
<style>
div.v-input--checkbox.error--text > div > div > div.v-input--selection-controls__input {
  border: 1px solid #ff5252;
}
</style>
