<template>
  <v-main class="v-main--dashboard">

    <div class="text-center pa-8" style="max-width:580px; margin:0 auto;">
      <img src="../assets/images/logo.svg" alt="" style="width:80px;">
      <h3 class="dark-red--text mt-5">Welcome to ATIB PROJECT TEST CHANGED TITLE</h3>
      <p>We where happy about your interests to join us.</p>
      <p>After you have verified you can login to our Memberportal and add all yours and familymembers necessary datas
        so we cancalculate the fees and you can proceed with payment.</p>
      <p>Afterpayment a waiting period from 90 days.</p>
      <p>Please verify your Email now to get logged in and registered: </p>
      <div class="mt-7">
        <v-btn style="min-width:300px!important;" to="/news"><strong>Verify and Login</strong></v-btn>

        <div class="d-flex mt-6" style="max-width:380px; margin:0 auto;">
          <div class="pt-3"><label>Hilfe benötigt?</label></div>
          <div class="pl-6">
            <div>
              <a :href="'tel:' + contacts.phoneNumber" class="iconBlock">
                <div class="iconBlock__icon">
                  <v-icon size="50" color="dark-red">icon-phone</v-icon>
                </div>
                <div class="iconBlock__text">{{ contacts.phoneNumber }}</div>
              </a>
            </div>
            <div class="mt-2">
              <a :href="'mailto:' + contacts.email" class="iconBlock">
                <div class="iconBlock__icon">
                  <v-icon size="48" color="dark-red">icon-envelope</v-icon>
                </div>
                <div class="iconBlock__text">{{ contacts.email }}</div>
              </a>
            </div>
          </div>
        </div>

      </div>
    </div>

    <div class="pa-6 mt-n2 white--text" style="background:#4d4d4d;">
      <div style="max-width:1280px; margin:0 auto;">
        <v-row dense>
          <v-col style="max-width:88px;"><img src="../assets/images/logo.svg" alt="" style="width:80px;"></v-col>
          <v-col class="pt-6 pl-5">
            <p>
              <strong>
                ATIB Union – Türkisch-islamische Union<br>
                für kulturelle und soziale Zusammenarbeit in Österreich
              </strong>
            </p>
            <p>
              <strong>
                Sonnleithnergasse 20, 1100 Wien<br>
                Email: cenaze@atib.at<br>
                www.atib.at<br>
                Telefon: 01 334 62 80-0
              </strong>
            </p>
            <p>
              <strong>
                IBAN: SI00 0000 0000 0000 000<br>
                BIC: ATATAT2X<br>
                ZVR:657301787
              </strong>
            </p>
          </v-col>
        </v-row>
      </div>
    </div>


  </v-main>
</template>
<script>
import contacts from "../config/contacts";

export default {
  name: 'Welcome',
  data: () => ({
    contacts
  }),
};
</script>
