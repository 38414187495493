<template>
  <div class="receipt">
    <div class="receipt__header" v-if="type == 0">
      <v-row class="el--responsive-3">
        <v-col>
          <div class="d-flex align-center">
            <img src="@/assets/images/logo.svg" alt="" style="width:70px;" class="mr-3">
            <p>ATIB Union – Türkisch-islamische Union <br>für kulturelle und soziale Zusammenarbeit in Österreich</p>
          </div>
        </v-col>
        <v-col style="max-width:280px">
          <p>Sonnleithnergasse 20, 1100 Wien <br> Email: cenaze@atib.at <br> www.atib.at <br> Telefon: 01 334 65 41
          </p>
        </v-col>
        <v-col style="max-width:280px">
          <p>IBAN: {{ contacts.iban }} <br> BIC: {{ contacts.bic }} <br> ZVR:{{ contacts.zvr }}</p>
        </v-col>
      </v-row>
    </div>
    <div class="receipt__body">
      <h4 class="dark-red--text"> {{ $t('new_payment') }}
      </h4>
      <hr class="hr mt-2 mb-4">

      <div class="d-flex justify-space-between">
        <div>
          <p>
            <strong>{{ user.name }}</strong><br>
            {{ mainMemberAddress.street }}<br>
            {{ mainMemberAddress.postal_code }} {{ mainMemberAddress.city }}<br>
            {{ mainMemberAddress.state }}
          </p>
        </div>
        <div class="text-right" v-if="mainMemberPhoneNumber">
          <p>Telefon: {{ mainMemberPhoneNumber.phone_number }} <br>Email: {{ user.email }} </p>
        </div>
      </div>

      <hr class="hr my-4">

      <div class="d-flex justify-space-between">
        <div>
          <p><strong>{{ $t('bill_id') }}: {{ items[0].id }}</strong></p>
        </div>
        <div>
          <p><strong>{{ $t('created_at') }}: {{ items[0].sale_invoice.date }}</strong></p>
        </div>
        <div class="text-right">
          <p><strong>{{ $t('due_date') }}: {{ items[0].sale_invoice.due_date }}</strong></p>
        </div>
      </div>
      <div class="tableScroll">
        <table class="table mt-5" style="min-width:600px;">
          <tr>
            <th>{{ $t('member') }}</th>
            <th>{{ $t('member_fee') }}</th>
            <!--            <th>{{ $t('tax') }}</th>-->
            <th>{{ $t('total') }}</th>
          </tr>
          <tr v-for="(item, index) in items[0].members" :key="index">
            <td>{{ item.name }}</td>
            <td>{{ item.price }} €</td>
            <!--            <td>00,00 €</td>-->
            <td>{{ item.price }} €</td>
          </tr>
          <tr class="tr--border-top">
            <td>{{ $t('total') }}</td>
            <td>{{ total }} €</td>
            <!--            <td>00,00 €</td>-->
            <td>{{ total }} €</td>
          </tr>
          <tr>
            <td><strong>{{ $t('payment_total') }}</strong></td>
            <td></td>
            <!--            <td></td>-->
            <td><strong>{{ total }} €</strong></td>
          </tr>
        </table>
      </div>

      <hr class="hr mt-0 mb-4">

      <div class="card">
        <!------------------------------>
        <h4 class="dark-red--text ma-0">{{ $t('payment_method') }}</h4>

        <v-radio-group
          v-model="selectedPaymentMethod"
          row
        >
          <v-radio
            v-for="(item,index) in paymentMethods"
            :key="index"
            :label="item.title"
            :value="item.value"
            class="v-radio--reverse"
          >
            <template v-slot:label>
              {{ item.label }}
            </template>
          </v-radio>
        </v-radio-group>

        <!------------------------------>
      </div>

      <hr class="hr my-4">

      <slot name="actions"></slot>
    </div>
  </div>
</template>
<script>
import contacts from "../config/contacts";

export default {
  name: 'Receipt',
  props: {
    type: {
      default: 0
    },
    items: {
      type: Array,
      default: () => {
        return [];
      }
    },
    total: {
      type: Number,
      default: 0
    }
  },
  data() {
    return {
      contacts,
      selectedPaymentMethod: 1,
    }
  },
  computed: {
    paymentMethods() {
      return [
        // {title: 'MasterCard', image: 'card-master.svg'},
        // {title: 'Visa', image: 'card-visa.svg'},
        // {title: 'AmericanExpress', image: 'card-am.svg'},
        {title: 'Bank', image: 'bank.svg', label: this.$t('bank_transaction'), value: 1}, //create pdf template 1
        // {title: 'QR', image: 'qr.svg'},//create pdf template 2
        // {title: 'PayPal', image: 'paypal.svg'},
      ];
    },
    user() {
      return this.$store.getters['user/user'];
    },
    mainMemberAddress() {
      if (this.user === undefined) {
        return null;
      }
      return this.user.main_member.addresses.find(item => item.type === 'domestic');
    },
    mainMemberPhoneNumber() {
      if (this.user === undefined) {
        return null;
      }
      return this.user.main_member.phone_numbers.find(item => item.is_main_number);
    }
  },
  methods: {},
}
</script>
