import Vue from 'vue';

export default {
    async precalculation() {
        const response = await Vue.axios.get('/api/v1/finance/sale-invoice/pre-calculation');
        return response.data;
    },
    async financialJournal(householdId, params) {
        const filter = params ? Object.keys(params).map((key) => `${key}=${params[key]}`).join('&') : '';
        const response = await Vue.axios.get('/api/v1/finance/financial-journal/household/' + householdId + '?' + filter);
        // const response = await Vue.axios.get('/api/v1/finance/financial-journal/household/' + householdId + '?filters[sale_invoice.status_id]=1');
        return response.data;
    },
    async createInvoice() {
        const response = await Vue.axios.post('/api/v1/finance/household/create-invoice');
        return response.data;
    },
    async confirmInvoice(saleInvoiceId) {
        const response = await Vue.axios.post('/api/v1/finance/sale-invoice/' + saleInvoiceId + '/confirm');
        return response.data;
    },
    async cancelInvoice(saleInvoiceId) {
        const response = await Vue.axios.post('/api/v1/finance/sale-invoice/' + saleInvoiceId + '/cancel');
        return response.data;
    },
    async renderPdf(invoiceId) {
        const response = await Vue.axios.get('/api/v1/finance/sale-invoice/' + invoiceId + '/render-pdf/1', {responseType: 'blob'});
        return response.data;
    },
}
