<template>
  <Dashboard>
    <div class="card">
      <div>
        <v-form style="max-width:760px;" ref="submember">
          <div v-if="newMember.id !== -1">
            <v-row dense class="align-center row--br">
              <v-col style="max-width:200px;" class="text-right"><label>{{ $t('status') }}</label></v-col>
              <v-col class="pl-6">
                <div class="d-flex align-center justify-space-between">
                  <h4 class="dark-red--text ma-0">
                    {{ newMember.status.toUpperCase() }}
                  </h4>
                </div>
              </v-col>
            </v-row>
          </div>
          <v-row dense class="align-center row--br">
            <v-col style="max-width:200px;" class="text-right">&nbsp;</v-col>
            <v-col class="pl-6">
              <div class="d-flex align-center justify-space-between">
                <h4 class="dark-red--text ma-0">{{ $t('personal_data') }}</h4>
              </div>
            </v-col>
          </v-row>
          <v-row dense class="align-center row--br">
            <v-col style="max-width:200px;" class="text-right"><label>{{ $t('first_name') }}</label></v-col>
            <v-col class="pl-6">
              <v-text-field outlined :placeholder="$t('first_name')"
                            :rules="[rules.required]"
                            :disabled="disableFields"
                            @change="isDirty = true"
                            v-model="newMember.first_name"></v-text-field>
            </v-col>
          </v-row>
          <v-row dense class="align-center row--br">
            <v-col style="max-width:200px;" class="text-right"><label>{{ $t('last_name') }}</label></v-col>
            <v-col class="pl-6">
              <v-text-field outlined :placeholder="$t('last_name')"
                            :rules="[rules.required]"
                            :disabled="disableFields"
                            @change="isDirty = true"
                            v-model="newMember.last_name"></v-text-field>
            </v-col>
          </v-row>
          <v-row dense class="align-center row--br">
            <v-col style="max-width:200px;" class="text-right"><label>{{ $t('date_of_birth') }}</label></v-col>
            <v-col class="pl-6">
              <date-picker
                :max="today"
                v-model="newMember.date_of_birth"
                :disable-fields="disableFields"
                :placeholder="$t('date_of_birth')"></date-picker>
            </v-col>
          </v-row>
          <v-row dense class="align-center row--br">
            <v-col style="max-width:200px;" class="text-right"><label>{{ $t('social_insurance_number') }}</label>
            </v-col>
            <v-col class="pl-6">
              <v-text-field outlined
                            :rules="[rules.required, rules.number]"
                            :placeholder="$t('social_insurance_number')"
                            @change="isDirty = true"
                            :disabled="disableFields"
                            v-model="newMember.social_insurance_number"
              ></v-text-field>
            </v-col>
          </v-row>
          <v-row dense class="align-center row--br">
            <v-col style="max-width:200px;" class="text-right"><label>{{ $t('citizenship') }}</label></v-col>
            <v-col class="pl-6">
              <v-select
                :rules="[rules.required]"
                v-model="newMember.citizenship_id"
                outlined
                :items="countries"
                :disabled="disableFields"
                item-text="text"
                item-value="id"
                @change="isDirty = true"
                attach
                :placeholder="$t('citizenship')"
              ></v-select>
            </v-col>
          </v-row>
          <v-row dense class="align-center row--br">
            <v-col style="max-width:200px;">
            </v-col>
            <v-col class="pl-6">
              <v-checkbox
                :rules="[rules.checkboxRequired]"
                v-model="newMember.permanent_residence"
                :disabled="disableFields"
                :label="$t('member_has_pernament_residence')"
              ></v-checkbox>
            </v-col>
          </v-row>
          <v-row dense class="align-center row--br">
            <v-col style="max-width:200px;" class="text-right"><label>{{ $t('turkish_identity_number') }}</label>
            </v-col>
            <v-col class="pl-6">
              <v-text-field outlined
                            @change="isDirty = true"
                            :placeholder="$t('turkish_identity_number')"
                            :rules="[validateTurkishIdentityNumber]"
                            v-model="newMember.turkish_id_no"
              >
              </v-text-field>
            </v-col>
          </v-row>
          <v-row dense class="align-center row--br">
            <v-col style="max-width:200px;" class="text-right"><label>{{ $t('place_of_birth') }}</label></v-col>
            <v-col class="pl-6">
              <v-text-field outlined
                            @change="isDirty = true"
                            :rules="[rules.required]"
                            :placeholder="$t('place_of_birth')"
                            :disabled="disableFields"
                            v-model="newMember.place_of_birth">
              </v-text-field>
            </v-col>
          </v-row>
          <v-row dense class="align-center row--br">
            <v-col style="max-width:200px;" class="text-right"><label>{{ $t('gender') }}</label></v-col>
            <v-col class="pl-6">
              <Gender v-model="newMember.gender"
                      :disabled="disableFields"
                      @change="getEligibilities"
              ></Gender>
            </v-col>
          </v-row>
          <v-row dense class="align-center row--br">
            <v-col style="max-width:200px;" class="text-right"><label>{{ $t('eligibility') }}</label></v-col>
            <v-col class="pl-6">
              <v-select-template :items="eligibilities"
                                 item-value="value"
                                 v-model="newMember.membership_basis"
                                 :rules="[rules.required]"
                                 :disabled="disableFields"
                                 label-prefix="membership_basis"
                                 :placeholder="$t('eligibility')"
                                 @change="getEligibilities"
              ></v-select-template>
            </v-col>
          </v-row>
          <v-row dense class="align-center row--br">
            <v-col style="max-width:200px;" class="text-right"><label>{{ $t('civil_status') }}</label></v-col>
            <v-col class="pl-6">
              <v-select-template :items="civil_statuses"
                                 item-value="value"
                                 v-model="newMember.civil_status"
                                 :rules="[rules.required]"
                                 :disabled="disableFields"
                                 label-prefix="civil_statuses"
                                 :placeholder="$t('civil_status')"
                                 @change="isDirty = true;"
              ></v-select-template>
            </v-col>
          </v-row>
          <v-row dense class="align-center row--br">
            <v-col style="max-width:200px;" class="text-right"><label>{{ $t('relationship') }}</label></v-col>
            <v-col class="pl-6">
              <relationship-type v-model="newMember.relations.relationship_type"
                                 :disabled="disableFields"
                                 :relationships="relationships"
                                 @change="isDirty = true;"
                                 :eligibility="newMember.membership_basis"></relationship-type>
            </v-col>
          </v-row>
          <v-row dense class="align-center row--br">
            <v-col style="max-width:200px;" class="text-right">&nbsp;</v-col>
            <v-col class="pl-6">
              <div class="d-flex align-center justify-space-between">
                <h4 class="dark-red--text ma-0">{{ $t('family_relationship') }}</h4>
              </div>
            </v-col>
          </v-row>
          <v-row dense class="align-center row--br">
            <v-col style="max-width:200px;" class="text-right"><label>{{ $t('name_father') }}</label></v-col>
            <v-col class="pl-6">
              <v-text-field v-model="newMember.father_name" outlined
                            :rules="[rules.required]"
                            @change="isDirty = true"
                            :disabled="disableFields"
                            :placeholder="$t('name_father')"></v-text-field>
            </v-col>
          </v-row>
          <v-row dense class="align-center row--br">
            <v-col style="max-width:200px;" class="text-right"><label>{{ $t('name_mother') }}</label></v-col>
            <v-col class="pl-6">
              <v-text-field v-model="newMember.mother_name" outlined
                            :rules="[rules.required]"
                            @change="isDirty = true"
                            :disabled="disableFields"
                            :placeholder="$t('name_mother')"></v-text-field>
            </v-col>
          </v-row>
          <v-row dense class="align-center row--br">
            <v-col style="max-width:200px;" class="text-right">&nbsp;</v-col>
            <v-col class="pl-6">
              <div class="d-flex align-center justify-space-between">
                <h4 class="dark-red--text ma-0">{{ $t('phone_number') }}</h4>
              </div>
            </v-col>
          </v-row>
          <v-row dense class="align-center row--br" v-for="(phoneNumber, index) in newMember.phone_numbers"
                 :key="index">
            <v-col style="max-width:200px;" class="text-right">
              <phone-type v-model="phoneNumber.phone_type"></phone-type>
            </v-col>

            <v-col class="pl-6">
              <v-text-field v-model="phoneNumber.phone_number" outlined
                            :rules="[rules.phone_number, rules.min, rules.max]"
                            :placeholder="$t('phone_number')"
                            :append-icon="phoneNumber.is_main_number && phoneNumber.id !== -1 ? undefined : 'icon-trash'"
                            @click:append="deletePhoneNumber(index, phoneNumber)"
              ></v-text-field>
            </v-col>
            <v-col style="max-width:40px;">
              <v-tooltip bottom>
                <template v-slot:activator="{ on }">
                  <v-simple-checkbox v-model="phoneNumber.is_main_number"
                                     @click.prevent="updateMainNumber(index, phoneNumber)"
                                     v-on="on"
                                     :disabled="phoneNumber.is_main_number"
                                     :ripple="false"></v-simple-checkbox>
                </template>
                <span>{{ $t('is_main_number') }}</span>
              </v-tooltip>
            </v-col>
          </v-row>
          <v-row dense class="align-center row--br">
            <v-col style="max-width:200px;" class="text-right">
            </v-col>
            <v-col class="pl-6">
              <v-btn @click="addPhoneNumber">
                <div class="iconSq iconSq--sm iconSq--border gradient mr-4 ml-n4">
                  <v-icon color="white" size="22">icon-plus</v-icon>
                </div>
                <div class="flex-grow-1 text-left">{{ $t('addNumber') }}</div>
              </v-btn>
            </v-col>
          </v-row>
          <v-row dense class="align-center row--br">
            <v-col style="max-width:200px;" class="text-right">&nbsp;</v-col>
            <v-col class="pl-6">
              <div class="d-flex align-center justify-space-between">
                <h4 class="dark-red--text ma-0">{{ $t('email') }}</h4>
                <div class="buttonContainer justify-end"></div>
              </div>
            </v-col>
          </v-row>
          <v-row dense class="align-center row--br">
            <v-col style="max-width:200px;" class="text-right"><label>{{ $t('email') }}</label></v-col>
            <v-col class="pl-6">
              <v-text-field outlined :placeholder="$t('email')"
                            :rules="[rules.email]"
                            @change="isDirty = true"
                            v-model="newMember.email"></v-text-field>
            </v-col>
          </v-row>
          <div v-if="newMember.id !== -1" style="margin-top: 10px">
            <v-row dense class="align-center row--br">
              <v-col style="max-width:200px;" class="text-right">&nbsp;</v-col>
              <v-col class="pl-6">
                <div class="d-flex align-center justify-space-between">
                  <h4 class="dark-red--text ma-0">{{ $t('mandatoryDocuments') }}</h4>
                  <div class="buttonContainer justify-end"></div>
                </div>
              </v-col>
            </v-row>
            <v-row dense class="align-center row--br">
              <v-col style="max-width:200px;" class="text-right"><label style="white-space: pre;">{{
                  $t('passport')
                }}</label></v-col>
              <v-col class="pl-6">
                <FileUpload :type="documentTypes.PASSPORT"
                            :member-id="newMember.id"
                            :document="passport"
                            @fileUploaded="updateUser"></FileUpload>
              </v-col>
            </v-row>
            <v-row dense class="align-center row--br">
              <v-col style="max-width:200px;" class="text-right"><label>{{ $t('residencePermit') }}</label></v-col>
              <v-col class="pl-6">
                <FileUpload :type="documentTypes.RESIDENCE_PERMIT"
                            :member-id="newMember.id"
                            :document="residencePermit"
                            @fileUploaded="updateUser"></FileUpload>
              </v-col>
            </v-row>
            <!--            <v-row dense class="align-center row&#45;&#45;br">-->
            <!--              <v-col style="max-width:200px;" class="text-right"><label>{{ $t('registrationForm') }}</label></v-col>-->
            <!--              <v-col class="pl-6">-->
            <!--                <FileUpload :type="documentTypes.REGISTRATION_FORM"-->
            <!--                            :member-id="newMember.id"-->
            <!--                            :document="registrationForm"-->
            <!--                            @fileUploaded="updateUser"></FileUpload>-->
            <!--              </v-col>-->
            <!--            </v-row>-->
            <v-row dense class="align-center row--br">
              <v-col style="max-width:200px;" class="text-right">&nbsp;</v-col>
              <v-col class="pl-6">
                <div class="d-flex align-center justify-space-between">
                  <h4 class="dark-red--text ma-0">{{ $t('otherDocuments') }}</h4>
                  <div class="buttonContainer justify-end"></div>
                </div>
              </v-col>
            </v-row>
            <v-row dense class="align-center row--br" v-for="document in otherDocuments" :key="document.id">
              <v-col style="max-width:200px;" class="text-right"><label>{{ $t('otherDocuments') }}</label></v-col>
              <v-col class="pl-6">
                <FileUpload :type="documentTypes.OTHER" :member-id="newMember.id" ref="documentOther"
                            @fileUploaded="otherDocumentUploaded"
                            :document="document"></FileUpload>
              </v-col>
            </v-row>
            <v-row dense class="align-center row--br" v-if="newDocumentShown">
              <v-col style="max-width:200px;" class="text-right"><label>{{ $t('addDocuments') }}</label></v-col>
              <v-col class="pl-6">
                <FileUpload :type="documentTypes.OTHER" :member-id="newMember.id"
                            @fileUploaded="otherDocumentUploaded"></FileUpload>
              </v-col>
            </v-row>
            <v-row dense class="align-center row--br">
              <v-col style="max-width:200px;" class="text-right">&nbsp;</v-col>
              <v-col class="pl-6">
                <v-btn @click="addDocument">
                  <div class="iconSq iconSq--sm iconSq--border gradient mr-4 ml-n4">
                    <v-icon color="white" size="22">icon-plus</v-icon>
                  </div>
                  <div class="flex-grow-1 text-left">{{ $t('upload') }}</div>
                </v-btn>
              </v-col>
            </v-row>
            <v-row dense class="align-center row--br mt-10" v-if="newMember.id !== -1">
              <v-col style="max-width:200px;" class="text-right">&nbsp;</v-col>
              <v-col class="pl-6">
                <div class="infoBlock infoBlock--red">
                  <div>
                    {{ $t('status') }}: <span class="dark-red--text">
                    {{ newMember.status.toUpperCase() }}
                  </span><br>
                    {{ $t('registration_date') }}:
                    <span class="dark-red--text">
                      {{ newMember.created_at | moment }}
                    </span><br>
                    {{ $t('deregistration_date') }}:
                    <span class="dark-red--text">
                      {{ newMember.inactive_from }}
                    </span><br>
                    {{ $t('deregistration_reason') }}:
                    <span class="dark-red--text" v-if="newMember.inactive_reason_id !== null">
                      {{ $t('inactive_reasons.member.' + newMember.inactive_reason_id + '.description') }}
                    </span>
                  </div>
                  <div class="iconSq iconSq--sm iconSq--border gradient gradient--black">
                    <v-icon color="white" size="18">icon-info</v-icon>
                  </div>
                </div>
              </v-col>
            </v-row>
          </div>

          <v-row dense class="align-center row--br">
            <v-col style="max-width:200px;" class="text-right">&nbsp;</v-col>
            <v-col class="pl-6">
              <div class="buttonContainer">
                <v-btn text reset class="mr-4" @click="close">
                  <span class="red--text">{{ $t('close') }}</span>
                </v-btn>
                <v-btn v-if="newMember.id !== -1" rounded @click="updateMember">
                  <v-icon>icon-user</v-icon>
                  <span>{{ $t('update') }}</span>
                </v-btn>
                <v-btn v-else rounded @click="addMember">
                  <v-icon>icon-user</v-icon>
                  <span>{{ $t('save') }}</span>
                </v-btn>
              </div>
            </v-col>
          </v-row>
        </v-form>

        <!-------------------------->
      </div>
    </div>
    <confirm ref="confirm"></confirm>
  </Dashboard>
</template>
<script>
import Dashboard from '@/layouts/Dashboard.vue';
import FileUpload from '@/components/FileUpload.vue';
import Gender from "../../components/Fields/Gender";
import memberService from "../../services/memberService";
import eligibilityService from "../../services/eligibilityService";
import userService from "../../services/userService";
import moment from "moment";
import {documentTypes} from "../../enumerations/documentTypes";
import PhoneType from "../../components/Fields/PhoneType";
import RelationshipType from "../../components/Fields/RelationshipType";
import membershipBasis from "../../config/membershipBasis";
import VSelectTemplate from "../../components/Fields/VSelectTemplate";
import phoneNumberService from "../../services/phoneNumberService";
import Confirm from "../../components/Dialogs/Confirm";
import DatePicker from "../../components/Fields/DatePicker";

export default {
  name: 'SubMember',
  components: {
    DatePicker,
    RelationshipType,
    PhoneType,
    Gender,
    Dashboard,
    FileUpload,
    VSelectTemplate,
    Confirm
  },
  data: () => ({
    membershipBasis,
    documentTypes,
    rules: {
      checkboxRequired: value => value || 'Required',
      required: value => !!value || 'Required.',
      email: value => !value || /^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,3})+$/.test(value) || 'Invalid E-mail address',
      phone_number: value => !value || /^\+?[0-9]+$/.test(value) || 'Invalid phone number',
      min: v => v.length >= 6 || 'Min 6 characters',
      max: v => v.length <= 15 || 'Max 15 characters',
      number: value => /^[0-9]*$/.test(value) || 'Only numbers alowed',
    },
    newDocumentShown: false,
    date: null,
    datePicker1: false,

    editUser: false,

    deleteWindowShown: false,
    today: new Date().toJSON(),

    newMember: {
      id: -1,
      phone_numbers: [],
      first_name: null,
      last_name: null,
      citizenship_id: null,
      turkish_id_no: null,
      gender: null,
      date_of_birth: null,
      social_insurance_number: null,
      civil_status: null,
      email: null,
      permanent_residence: false,
      place_of_birth: null,
      father_name: null,
      mother_name: null,
      phone: [
        {
          phone_type: 'private_mobile',
          phone_number: '',
          is_main_number: false
        }
      ],
      documents: [],
      relations: {
        relationship_type: null,
      },
      editedUserId: null
    },
    selectedMember: null,
    isDirty: false,
    eligibilities: [],
    relationships: [],
    civil_statuses: []
  }),
  computed: {
    countries() {
      return this.$store.getters['countries/translatedCountries'];
    },
    user() {
      return this.$store.getters['user/user'];
    },
    otherDocuments() {
      return this.newMember.documents.filter(document => document.type === documentTypes.OTHER);
    },
    passport() {
      return this.newMember.documents.find(document => document.type === documentTypes.PASSPORT);
    },
    residencePermit() {
      return this.newMember.documents.find(document => document.type === documentTypes.RESIDENCE_PERMIT);
    },
    registrationForm() {
      return this.newMember.documents.find(document => document.type === documentTypes.REGISTRATION_FORM);
    },
    disableFields() {
      return this.newMember.status === 'active'
        || this.newMember.status === 'waiting'
        || this.newMember.status === 'inactive';
    },
  },
  filters: {
    moment(date) {
      if (date === null) {
        return null;
      }
      return moment(date).format('DD.MM.YYYY');
    }
  },
  mounted() {
    if (this.$route.params.id !== undefined && !isNaN(parseInt(this.$route.params.id))) {
      this.newMember = JSON.parse(JSON.stringify(this.user.sub_members
        .find(item => item.id === parseInt(this.$route.params.id))
      ));
      this.getEligibilities();
      this.$refs.submember.resetValidation();
      return;
    }
    this.setNewMember();
    this.$refs.submember.resetValidation();
    this.getEligibilities();
  },
  methods: {
    getEligibilities() {
      this.isDirty = true;
      let data = {};
      if (this.newMember.gender) {
        data['gender_id'] = this.newMember.gender === 'M' ? 1 : 2;
      }
      if (this.newMember.membership_basis) {
        data['eligibility_id'] = this.eligibilityMapper(this.newMember.membership_basis);
      }
      eligibilityService.index(data)
        .then(res => {
          this.eligibilities = res.eligibilities.filter(item => item.name !== 'nb').map(item => {
            return {
              value: item.name,
              id: item.id,
            }
          });
          if (this.newMember.status !== 'new') {
            this.eligibilities = res.eligibilities.map(item => {
              return {
                value: item.name,
                id: item.id,
              }
            });
          }

          this.civil_statuses = res.civil_statuses.map(item => {
            return {
              value: item.name,
              id: item.id,
            }
          });
          this.relationships = res.relationships;
        });
    },
    eligibilityMapper(eligibility) {
      const eligilities = {
        spouse: 1,
        child: 2,
        child_with_disability: 3,
      };
      return eligilities[eligibility];
    },
    addMember() {
      if (!this.$refs.submember.validate()) {
        this.$store.dispatch('alert/toggleShow', true);
        this.$store.dispatch('alert/setMessage', this.$t('missing_fields'));
        return;
      }

      if (this.isChildAndOlderThanThirtyYears()) {
        this.$store.dispatch('alert/toggleShow', true);
        this.$store.dispatch('alert/setMessage', this.$t('child_older_than_thirty_years'));
        return;
      }

      let data = {
        first_name: this.newMember.first_name,
        last_name: this.newMember.last_name,
        citizenship_id: this.newMember.citizenship_id,
        gender: this.newMember.gender,
        date_of_birth: moment(this.newMember.date_of_birth, 'YYYY-MM-DD').format('DD.MM.YYYY'),
        social_insurance_number: this.newMember.social_insurance_number,
        civil_status: this.newMember.civil_status,
        email: this.newMember.email,
        permanent_residence: this.newMember.permanent_residence,
        father_name: this.newMember.father_name,
        mother_name: this.newMember.mother_name,
        membership_basis: this.newMember.membership_basis,
        place_of_birth: this.newMember.place_of_birth,
        relationship_type: this.newMember.relations.relationship_type,
        phone_numbers: this.newMember.phone_numbers
      };
      if (this.newMember.turkish_id_no !== null && this.newMember.turkish_id_no !== '') {
        data.turkish_id_no = this.newMember.turkish_id_no;
      }

      memberService.store(data)
        .then(() => {
          userService.verify().then(() => {
            this.isDirty = false;
            this.$router.push({name: 'sub-member'});
          });
        });
    },

    updateMember() {
      if (!this.$refs.submember.validate()) {
        return;
      }

      if (this.isChildAndOlderThanThirtyYears()) {
        this.$store.dispatch('alert/toggleShow', true);
        this.$store.dispatch('alert/setMessage', this.$t('child_older_than_thirty_years'));
        return;
      }

      let data = this.newMember;
      data.date_of_birth = moment(this.newMember.date_of_birth, 'YYYY-MM-DD').format('DD.MM.YYYY');

      memberService.update(this.newMember.id, data)
        .then(() => {
          userService.verify();
          this.isDirty = false;
          this.$router.push({name: 'sub-member'});
        });
    },

    addPhoneNumber() {
      this.newMember.phone_numbers.push({
        id: -1,
        phone_number: '',
        phone_type: 'private_mobile',
        is_main_number: this.newMember.phone_numbers.length === 0
      });
    },
    close() {
      this.$router.push({name: 'sub-member'});
    },
    setNewMember() {
      this.newMember = {
        id: -1,
        status: 'new',
        phone_numbers: [],
        first_name: '',
        last_name: '',
        citizenship_id: null,
        turkish_id_no: null,
        gender: null,
        date_of_birth: null,
        social_insurance_number: null,
        civil_status: null,
        email: '',
        permanent_residence: false,
        place_of_birth: '',
        father_name: '',
        mother_name: '',
        membership_basis: null,
        phone: [
          {
            phone_type: 'private_mobile',
            phone_number: '',
            is_main_number: false
          }
        ],
        documents: [],
        relations: {
          relationship_type: null
        }
      }
    },
    deletePhoneNumber(index, phoneNumber) {
      if (phoneNumber.is_main_number && this.newMember.phone_numbers.length > 1) {
        return;
      }
      if (phoneNumber.id === -1) {
        this.newMember.phone_numbers.splice(index, 1);
        return;
      }
      phoneNumberService.delete(phoneNumber.id).then(() => {
        this.newMember.phone_numbers.splice(index, 1);
      });
    },
    updateMainNumber(index, phoneNumber) {
      if (!phoneNumber.is_main_number) {
        return;
      }

      this.newMember.phone_numbers.forEach((item, i) => {
        if (i !== index) {
          item.is_main_number = false;
        }
      });
    },
    addDocument() {
      this.newDocumentShown = true;
    },
    updateUser() {
      this.newMember = this.user.sub_members.find(member => member.id === parseInt(this.$route.params.id));
    },
    otherDocumentUploaded() {
      this.updateUser();
      this.newDocumentShown = false;
    },
    validateTurkishIdentityNumber(value) {
      if (value === null || value === '') {
        return true;
      }

      value = String(value);
      if (!(/^[1-9]\d{10}$/).test(value)) return false;

      console.log(value);
      const digits = value.split('');
      // store last 2 digits (10th and 11th) which are actually used for validation
      const d10 = Number(digits[9]);
      const d11 = Number(digits[10]);
      // we'll also need the sum of first 10 digits for validation
      let sumOf10 = 0;
      let evens = 0;
      let odds = 0;

      digits.forEach((d, index) => {
        d = Number(d);
        if (index < 10) sumOf10 += d;
        if (index < 9) {
          if ((index + 1) % 2 === 0) {
            evens += d;
          } else {
            odds += d;
          }
        }
      });

      // check if the unit-digit of the sum of first 10 digits equals to the 11th digit.
      if (sumOf10 % 10 !== d11) return false;

      // check if unit-digit of the sum of odds * 7 and evens * 9 is equal to 10th digit.
      if (((odds * 7) + (evens * 9)) % 10 !== d10) return false;

      // check if unit-digit of the sum of odds * 8 is equal to 11th digit.
      if ((odds * 8) % 10 !== d11) return false;

      return true;
    },
    dateOfBirthInput(val) {
      if (val.length === 4 || val.length === 7) {
        this.newMember.date_of_birth = val + '-'

      }
      if (val.length > 10) {
        this.newMember.date_of_birth = val.slice(0, -2);
      }
      return false;
    },
    validateDateOfBirth(value) {
      if (value === null || value === '' || value.length < 10) {
        return false;
      }

      if (moment(value, 'DD.MM.YYYY').isBefore(moment())) {
        return true;
      }

      return false;
    },
    isChildAndOlderThanThirtyYears() {
      if (this.newMember.membership_basis === 'child_with_disability') {
        return false;
      }

      if (this.newMember.relations.relationship_type === 'son'
        || this.newMember.relations.relationship_type === 'daughter') {
        const thirtyYearsAgo = moment().subtract(30, 'years');

        if (moment(this.newMember.date_of_birth).isAfter(thirtyYearsAgo)) {
          return false;
        }
        return true;
      }

      return false;
    }
  },
  beforeRouteLeave(to, from, next) {
    if (!this.isDirty) {
      next();
      return;
    }

    this.$refs.confirm.confirm(this.$t('confirmUnsavedDataProceed'))
      .then(next)
      .catch(() => {
        next(false);
      });
  }
};
</script>
