<template>
  <v-select
    :items="items"
    :rules="[rules.required]"
    v-bind:value="value"
    :item-text="getLabel"
    item-value="value"
    :placeholder="$t('civil_status')"
    :menu-props="{ bottom: true, offsetY: true, contentClass:'v-select-dropdown' }"
    v-on:change="updateValue($event)"
    hide-details
    attach
    :disabled="disabled"
    outlined></v-select>
</template>

<script>
// import civilStatuses from "../../config/civilStatuses";

export default {
  name: "CivilStatus",
  data: () => ({
    // civilStatuses,
    rules: {
      required: value => !!value || 'Required.',
    },
  }),
  props: ['value', 'disabled', 'items'],
  methods: {
    getLabel(item) {
      return this.$t(`civil_statuses.${item.value}`);
    },
    updateValue: function (value) {
      this.$emit('input', value);
    }
  }
}
</script>

<style scoped>

</style>
