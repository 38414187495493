<template>
  <aside class="sidebar">

    <div class="sidebar__top">

      <div class="iconSq iconSq--lg iconSq--border gradient gradient--black">
        <v-icon color="white" size="42">{{ activeIcon }}</v-icon>
      </div>

    </div>
    <div class="sidebar__middle">

      <v-list class="v-list--sideMenu">
        <v-list-item
          v-for="(item, index) in sideMenu"
          :disabled="!$existingMember"
          :key="index"
          :to="item.path"
          :exact="true"
        >
          {{ item.title }}
        </v-list-item>
      </v-list>

    </div>
    <div class="sidebar__bottom">
      <div class="infoBlock" style="min-height: 0px; margin-bottom: 10px; max-height: 60px">
        <template>
          <div class="d-flex align-center">
            <v-icon color="white" size="18">icon-phone</v-icon>
            <div style="margin-left: 70px">
              <a :href="'tel:' + contacts.phoneNumber" class="iconBlock">
                <div class="white--text">{{ contacts.phoneNumber }}</div>
              </a>
            </div>
          </div>
        </template>
      </div>
      <div class="infoBlock">
        <template>
          <div class="donutC donutC--static">
            <div class="donutC__value"><i class="icon-check"></i></div>
          </div>
          <div>
            {{ stateText }}
            <div class="iconSq iconSq--sm iconSq--border gradient gradient--black">
              <v-icon color="white" size="18">icon-info</v-icon>
            </div>
          </div>
        </template>
      </div>
    </div>

  </aside>
</template>
<script>
import contacts from "../config/contacts";

export default {
  name: 'Sidebar',
  components: {},
  data: () => ({
    activeIcon: 'icon-user',
    contacts
  }),
  computed: {
    chartValue: function () {
      var _this = this, value = 0;
      _this.sideMenu.map(function (item) {
        if (item.path == _this.$route.path) {
          value = item.value;
        }
      });
      return value;
    },
    sideMenu() {
      var menu = [
        {title: this.$t('menu.news'), path: '/news', icon: 'icon-envelope', value: 0},
        {title: this.$t('menu.main_member'), path: '/main-member', icon: 'icon-member', value: 25},
        {title: this.$t('menu.household'), path: '/household', icon: 'icon-household', value: 50},
        {title: this.$t('menu.sub_members'), path: '/sub-member', icon: 'icon-people', value: 75},
        {title: this.$t('menu.payment'), path: '/payment', icon: 'icon-wallet', value: 100},
      ];

      if (this.$existingMember) {
        menu.splice(4, 0, {title: this.$t('menu.representations'), path: '/substitutes', icon: 'icon-user'});
        return menu;
      }

      const step = this.$store.getters['user/step'];

      let items = [];
      for (let i = 0; i <= step; i++) {
        items.push(menu[i]);
      }

      return items;
    },
    user() {
      return this.$store.getters['user/user'];
    },
    stateText() {
      return this.user.household.status === 'active' ? this.$t('application.valid') : this.user.household.status === 'waiting' ? this.$t('application.waiting') : this.$t('application.in_progress');
      //  add for waiting and for new
    }
  },
  mounted: function () {
    var _this = this;
    _this.sideMenu.map(function (item) {
      if (item.path == _this.$route.path) {
        _this.activeIcon = item.icon;
      }
    });

    //Append icon
    var iconContainer = document.querySelector('.dashboard__icon'),
      icon = document.createElement('i');

    if (_this.activeIcon) {
      icon.classList.add(_this.activeIcon);
      iconContainer.appendChild(icon);
    }
  }
};
</script>
