<template>
  <Dashboard>
    <div class="card">

      <div>
        <!-------------------------->
        <v-data-table
          :headers="tableHeaders"
          :items="user.sub_members"
          :items-per-page="-1"
          :hide-default-footer="true"
          :sort-by="['created_at']"
          :sort-desc="false"
          class="v-data-table--collapse"
          :class="{'collapse':collapse}"
        >
          <template>
            <v-btn icon style="width:40px!important; height:40px!important; min-width:40px!important;"
                   @click="tableCollapse">
              <v-icon>icon-chevron-up</v-icon>
            </v-btn>
          </template>
          <template v-slot:item.status="{item}">
            <v-badge :color="badgeColor(item.status)">
              <span slot="badge">{{ item.status.toUpperCase() }}</span>
            </v-badge>
          </template>
          <template v-slot:item.date_of_birth="{item}">
            <div class="flex-grow-1">{{ item.date_of_birth }}</div>
          </template>
          <template v-slot:item.created_at="{item}">
            <div class="flex-grow-1">{{ item.created_at | moment }}</div>
          </template>
          <template v-slot:item.actions="{ item }">
            <div class="d-flex align-center">
              <div class="d-flex">
                <v-tooltip bottom>
                  <template v-slot:activator="{ on, attrs }">
                    <v-btn icon reset v-bind="attrs" v-on="on" class="ml-4" @click="memberEdit(item)">
                      <v-icon color="red" size="22">icon-edit</v-icon>
                    </v-btn>
                  </template>
                  <span>{{ $t('change') }}</span>
                </v-tooltip>
                <v-tooltip bottom v-if="item.status === 'new'">
                  <template v-slot:activator="{ on, attrs }">
                    <v-btn icon reset v-bind="attrs" v-on="on" class="ml-4" @click="memberBeDeleted(item)">
                      <v-icon color="red" size="22">icon-trash</v-icon>
                    </v-btn>
                  </template>
                  <span>{{ $t('delete') }}</span>
                </v-tooltip>
              </div>
            </div>

          </template>
        </v-data-table>

        <v-btn rounded class="mt-5" @click="addNewMember">
          <v-icon>icon-user</v-icon>
          <span>{{ $t('add_member') }}</span>
        </v-btn>
        <!-------------------------->
      </div>
    </div>
    <div class="buttonContainer justify-space-between mt-3">
      <v-btn to="/household">
        <div class="iconSq iconSq--sm iconSq--border gradient">
          <v-icon color="white" size="22">icon-chevron-left</v-icon>
        </div>
        <div class="flex-grow-1 text-center">{{ $t('navigationButtons.backToHousehold') }}</div>
      </v-btn>
      <v-btn to="/substitutes" v-if="$existingMember">
        <div class="flex-grow-1 text-center">{{ $t('navigationButtons.nextToSubstitutes') }}</div>
        <div class="iconSq iconSq--sm iconSq--border gradient">
          <v-icon color="white" size="22">icon-chevron-right</v-icon>
        </div>
      </v-btn>
      <v-btn to="/payment" v-else>
        <div class="flex-grow-1 text-center">{{ $t('navigationButtons.nextToPayments') }}</div>
        <div class="iconSq iconSq--sm iconSq--border gradient">
          <v-icon color="white" size="22">icon-chevron-right</v-icon>
        </div>
      </v-btn>
    </div>

    <!---------------------------------------------------------->

    <app-dialog :visible.sync="deleteWindowShown" type="alert">
      <template v-slot:body>
        <div class="text-center">
          <h3 class="dark-red--text">{{ $t('delete') }} ?</h3>
          <p class="mt-5">
            {{ $t('confirmAction') }}</p>
        </div>
      </template>
      <template v-slot:actions>
        <v-btn @click="deleteMember" color="green"><strong>{{ $t('confirm') }}</strong></v-btn>
        <v-btn @click="deleteWindowShown = false;"><strong>{{ $t('cancel') }}</strong></v-btn>
      </template>
    </app-dialog>

    <!---------------------------------------------------------->
    <Manual :step="2" :visible="showManual" @toggle="showManual = !showManual" v-if="!$existingMember"></Manual>
    <confirm ref="confirm"></confirm>
  </Dashboard>
</template>
<script>
import Dashboard from '@/layouts/Dashboard.vue';
import Manual from '@/components/Manual.vue';
import AppDialog from '@/components/Dialog.vue';
import memberService from "../../services/memberService";
import userService from "../../services/userService";
import moment from "moment";
import Confirm from "../../components/Dialogs/Confirm";

export default {
  name: 'SubMember',
  components: {
    Dashboard,
    Manual,
    AppDialog,
    Confirm
  },
  data: () => ({
    showManual: false,
    collapse: false,
    deleteWindowShown: false,
    selectedMember: null
  }),
  computed: {
    tableHeaders() {
      return [
        {
          sortable: false,
          value: 'collapse',
          width: '80px'
        },
        {text: this.$t('status'), value: 'status'},
        {text: this.$t('first_name'), value: 'first_name'},
        {text: this.$t('last_name'), value: 'last_name'},
        {text: this.$t('date_of_birth'), value: 'date_of_birth'},
        {text: this.$t('entry_date'), value: 'created_at'},
        {text: this.$t('actions'), value: 'actions', sortable: false, width: '100px'},
      ];
    },
    user() {
      return this.$store.getters['user/user'];
    },
  },
  filters: {
    moment(date) {
      if (date === null) {
        return null;
      }
      return moment(date).format('DD.MM.YYYY');
    }
  },
  mounted() {
    this.$store.dispatch('user/setStep', 3);
  },
  methods: {
    tableCollapse() {
      this.collapse = !this.collapse;
    },
    memberEdit(member) {
      this.$router.push({name: 'memberEdit', params: {id: member.id}});
      return;
    },
    addNewMember() {
      this.$router.push({name: 'memberAdd'});
      return;
    },
    memberBeDeleted(member) {
      this.selectedMember = member;
      this.deleteWindowShown = true;
    },
    deleteMember() {
      memberService.delete(this.selectedMember.id)
        .then(() => {
          userService.verify();
          this.deleteWindowShown = false;
        });
    },
    badgeColor(status) {
      if (status === 'active') {
        return 'success';
      }

      if (status === 'waiting') {
        return 'warning';
      }

      if (status === 'inactive') {
        return 'red';
      }

      return 'blue';
    },
  },
};
</script>
