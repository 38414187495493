import Vue from "vue";
import Vuex from "vuex";

// import the auto exporter
import modules from '../stores/index.js';
import VuexPersistence from 'vuex-persist';

Vue.use(Vuex);

const vuexLocal = new VuexPersistence({
    storage: window.localStorage,
    reducer: (state) => ({
        user: state.user
    })
});

export default new Vuex.Store({
    modules,
    plugins: [vuexLocal.plugin]
});
