const state = {
    show: false,
    message: 'THIS IS MESSAGE'
};

const getters = {
    show(state) {
        return state.show
    },
    message(state) {
        return state.message
    },
};

const mutations = {
    toggleShow(state, value) {
        state.show = value;
    },
    setMessage(state, value) {
        state.message = value;
    },
};

const actions = {
    toggleShow(context, value) {
        context.commit('toggleShow', value);
    },
    setMessage(context, value) {
        context.commit('setMessage', value);
    },
};

export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations
};
