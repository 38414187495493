<template>
    <div class="dcIcon">
        <div class="dcIcon__icon"><svg version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px" viewBox="0 0 80.2 53.1" style="enable-background:new 0 0 80.2 53.1;" xml:space="preserve"><path style="fill:#FFFFFF;stroke:#C92127;stroke-width:3;stroke-miterlimit:10;" d="M78.7,26.5c0,4.3-1.8,8.2-4.6,11.1 s-6.7,4.6-11,4.6c-4.3,0-8.2-1.8-11.1-4.6c-1-1-2.7-0.8-3.3,0.4c-1.2,2.3-2.7,4.4-4.5,6.2c-5.1,5.1-12.3,8-20.2,7.2 C12.2,50.3,2.8,40.8,1.6,29.1C0.8,21.2,3.7,13.9,8.8,8.8c4.5-4.5,10.8-7.3,17.7-7.3c6.9,0,13.2,2.8,17.7,7.3 c1.8,1.8,3.3,3.9,4.5,6.2c0.7,1.3,2.3,1.5,3.3,0.4c0,0,0,0,0,0c2.8-2.8,6.7-4.6,11.1-4.6c4.3,0,8.2,1.8,11,4.6S78.7,22.2,78.7,26.5z "/></svg></div>
        <div class="dcIcon__number">{{number}}</div>
    </div>
</template>
<script>
export default {
    name: 'Double-circle-icon',
    props:{
        number: {
            default:0
        }
    },
    data() {
        return {
            sortByAsc:false
        }
    }
}
</script>