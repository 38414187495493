<template>
  <Dashboard class="dashboard--news">
    <div class="card">

      <div class="d-flex flex-wrap align-center mb-4">
        <div class="d-inline-flex align-center mr-4">
          <h3 class="dark-red--text ma-0 mr-2">{{ $t('menu.read') }}</h3>
          <Dc :number="readed"></Dc>
        </div>
        <div class="d-inline-flex align-center">
          <h3 class="dark-red--text ma-0 mr-2">{{ $t('menu.unread') }}</h3>
          <Dc :number="unreaded"></Dc>
        </div>
      </div>

      <v-expansion-panels class="accordion ml-n5">
        <v-expansion-panel
          v-for="(item,index) in news"
          :key="index"
          @click="onOpen(index)"
          :class="{'wOpen': item.read_at === null}"
        >
          <v-expansion-panel-header>
            <h4 v-if="item.data.title">{{ item.data.title }}</h4>
            <time>{{ item.created_at | moment }}</time>
          </v-expansion-panel-header>
          <v-expansion-panel-content>
            <div v-html="item.data.data"></div>
            <!--            <div class="buttonContainer mt-2 justify-end" v-if="item.id !== -1">-->
            <!--              <v-btn @click="newsRepyShown=true">{{ $t('news.reply') }}</v-btn>-->
            <!--              <v-btn>{{ $t('news.delete') }}</v-btn>-->
            <!--            </div>-->
          </v-expansion-panel-content>
        </v-expansion-panel>
      </v-expansion-panels>

    </div>

    <!---------------------------------------------------------->

    <app-dialog :visible.sync="dialog_1">
      <template v-slot:body>
        <div class="text-center">
          <h3 class="dark-red--text">{{ $t('welcomeNews').toUpperCase() }}</h3>
        </div>

        <p class="mt-5">In unserer 1. Nachricht an Sie „Bewerbung als neues Mitglied“ finden Sie eine detaillierte
          Beschreibung zu allen erforderlichen Schritten um Mitglied bei uns werden zu können. Wir benötigen folgende
          Informationen von Ihnen:</p>
        <p class="dark-red--text">
          1. Ihre eigenen Daten als Hauptmitglied <br>
          2. Haushalt für den die Mitgliedschaft abgeschlossen werden soll <br>
          3. Die Daten der Untermitglieder welche mit eingetragen werden sollen <br>
          4. Ihre Zahlungsdaten um die Zahlung der erforderlichen Gebühren durchzuführen
        </p>
      </template>
      <template v-slot:actions>
        <v-btn @click="dialog_1 = false;"><strong>Weiter zur Eingabe…</strong></v-btn>
      </template>
    </app-dialog>

    <!---------------------------------------------------------->
    <div class="buttonContainer justify-end mt-3">
      <v-btn to="/main-member">
        <div class="flex-grow-1 text-center">{{ $t('navigationButtons.nextToMainMember') }}</div>
        <div class="iconSq iconSq--sm iconSq--border gradient">
          <v-icon color="white" size="22">icon-chevron-right</v-icon>
        </div>
      </v-btn>
    </div>

    <app-dialog :visible.sync="newsRepyShown">
      <template v-slot:body>
        <div class="text-center mb-4">
          <h3 class="dark-red--text">{{ $t('reply').toUpperCase() }}</h3>
        </div>
        <v-textarea outlined hide-details></v-textarea>
      </template>
      <template v-slot:actions>
        <v-btn @click="newsRepyShown = false;"><strong>{{ $t('send') }}</strong></v-btn>
        <v-btn @click="newsRepyShown = false;"><strong>{{ $t('close') }}</strong></v-btn>
      </template>
    </app-dialog>
    <Manual :step="0" :visible="showManual" @toggle="showManual = !showManual" v-if="!$existingMember"></Manual>
  </Dashboard>
</template>
<script>
import Dashboard from '@/layouts/Dashboard.vue';
import Manual from "../../components/Manual";
import Dc from '@/components/DoubleCircleIcon.vue';
import AppDialog from '@/components/Dialog.vue';
import newsService from "../../services/newsService";
import moment from "moment";

export default {
  name: 'News',
  components: {
    Dashboard,
    Dc,
    AppDialog,
    Manual
  },
  data: () => ({
    showManual: false,
    news: [],

    dialog_1: false,
    newsRepyShown: false
  }),
  mounted() {
    // this.createNotification();
    this.getNotifications();
    this.$store.dispatch('user/setStep', 0);
  },
  computed: {
    user() {
      return this.$store.getters['user/user'];
    },
    unreaded() {
      return this.news.filter(item => item.read_at === null).length;
    },
    readed() {
      return this.news.filter(item => item.read_at !== null).length;
    },
  },
  filters: {
    moment(date) {
      if (date === null) {
        return null;
      }
      return moment(date).format('DD.MM.YYYY');
    }
  },
  methods: {
    onOpen(i) {
      var _this = this;
      _this.news[i].seen = true;

      if (this.news[i].id === -1) {
        this.news[i].read_at = moment.now();
        return;
      }
      newsService.markAsRead(this.news[i].id);
    },
    getNotifications() {
      newsService.index().then(res => {
        if (res.data.length < 1) {
          this.news.push({
            id: -1,
            data: {
              data: this.$t('welcomeMessage.body'),
              title: this.$t('welcomeMessage.title')
            },
            created_at: moment.now(),
            read_at: null
          });
          return;
        }
        this.news = res.data;
      });
    },
    createNotification() {
      newsService.store(this.user.household.id,
        {
          "title": "TEST123",
          "data": "<div><h4>News Details</h4><span>THIS IS SOME RANDOM TEST</span></div>"
        }
      );
    }
  },
};
</script>
