const phoneTypes = [
    {
        "value": "private_landline"
    },
    {
        "value": "private_mobile"
    },
    {
        "value": "work_landline"
    },
    {
        "value": "work_mobile"
    },
];

export default phoneTypes;
