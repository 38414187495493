import Vue from 'vue';
import VueRouter from 'vue-router';

import Login from '../views/Login.vue';
import Welcome from '../views/Welcome.vue';
import MyAccount from '../views/Member/MyAccount.vue';
import News from '../views/Member/News.vue';
import Household from '../views/Member/Household.vue';
import MainMember from '../views/Member/MainMember.vue';
import SubMember from '../views/Member/SubMember.vue';
import Payment from '../views/Member/Payment.vue';
import Substitutes from '../views/Member/Substitutes.vue';
import EditSubmember from '../views/Submember/Edit';
import EditSubstitutes from '../views/Substitute/Add'
import Store from "../plugins/store";
import Register from "../views/Register";

Vue.use(VueRouter);

const routes = [
    {
        path: '/',
        name: 'Login',
        component: Login
    },
    {
        path: '/register',
        name: 'Register',
        component: Register
    },
    {
        path: '/welcome',
        name: 'Welcome',
        component: Welcome,
    },
    {
        path: '/my-account',
        name: 'my-account',
        component: MyAccount,
    },
    {
        path: '/news',
        name: 'news',
        component: News,
    },
    {
        path: '/household',
        name: 'household',
        component: Household,
    },
    {
        path: '/main-member',
        name: 'main-member',
        component: MainMember,
    },
    {
        path: '/sub-member',
        name: 'sub-member',
        component: SubMember,
    },
    {
        path: '/sub-member/:id',
        name: 'memberEdit',
        component: EditSubmember,
    },
    {
        path: '/sub-member/add',
        name: 'memberAdd',
        component: EditSubmember,
    },
    {
        path: '/payment',
        name: 'payment',
        component: Payment,
    },
    {
        path: '/substitutes',
        name: 'substitutes',
        component: Substitutes,
    },
    {
        path: '/substitutes/:id',
        name: 'substitutesEdit',
        component: EditSubstitutes,
    },
    {
        path: '/substitutes/add',
        name: 'substitutesAdd',
        component: EditSubstitutes,
    },

    /*
    {
        path: '/new-member',
        name: 'new-member',
        component: Index,
        children: [
            {
              path: '/',
              component: NewMemberNews
            },
            {
                path: 'household',
                component: NewMemberHousehold
            },
            {
                path: 'main',
                component: NewMemberMainMember
            },
            {
                path: 'sub',
                component: NewMemberSubMember
            },
            {
                path: 'payment',
                component: NewMemberPayment
            }
        ]
    },
    */
    {
        path: "*",
        redirect: "/",
    },
];

const router = new VueRouter({
    mode: 'history',
    base: '/member-ui/',
    routes,
});

router.beforeEach((to, from, next) => {
    if (Store.state.user.loggedIn) {
        if (to.path === '/') {
            return next({name: 'news'})
        }
        return next();
    }

    if (to.path === '/register') {
        return next();
    }

    if (to.path !== '/') {
        return next({name: 'Login'})
    }

    return next();
});

export default router;
