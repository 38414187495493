<template>
  <div class="dashboard">
    <div class="dashboard__header">
      <Header></Header>
    </div>
    <div class="dashboard__sidebar">
      <Sidebar></Sidebar>
    </div>
    <main class="dashboard__main">
      <div class="dashboard__inner">
        <slot></slot>
      </div>
      <div class="dashboard__icon"></div>
    </main>
  </div>
</template>
<script>
import Header from '@/components/Header.vue';
import Sidebar from '@/components/Sidebar.vue';

export default {
  name: 'Dashboard',
  props: {},
  components: {
    Header,
    Sidebar
  },
  data: () => ({}),
};
</script>
