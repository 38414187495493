<template>
  <v-dialog
    :value="shown"
    :max-width="width"
    persistent
    content-class="v-dialog--alert"
  >
    <v-card>
      <v-card-text>
        <p v-html="message"></p>
      </v-card-text>
      <v-card-actions>
        <v-btn @click="close">{{ $t('close') }}</v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>
<script>
export default {
  name: "Alert",
  components: {},
  props: {
    width: {
      default: 860
    },
  },
  data: () => ({}),
  computed: {
    shown() {
      return this.$store.getters['alert/show'];
    },
    message() {
      if (this.$te(`error_messages.${this.$store.getters['alert/message']}`)) {
        return this.$t(`error_messages.${this.$store.getters['alert/message']}`);
      }

      return this.$t(this.$store.getters['alert/message']);
    }
  },
  methods: {
    close() {
      this.$store.dispatch('alert/toggleShow', false);
    }
  }
}
</script>

<style scoped>

</style>
