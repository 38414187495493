import i18n from "../plugins/i18n";
import Vue from "vue";
import {languages} from "../enumerations/languages";

const state = {
    language: 'en'
};

const getters = {
    language(state) {
        return state.language
    },
};

const mutations = {
    setLanguage(state, value) {
        const values = Object.values(languages);
        const index = values.indexOf(value);
        const language = Object.keys(languages)[index];

        i18n.locale = language.toLowerCase();
        state.language = language.toLowerCase();
    },
    changeLanguage(state) {
        const keys = Object.keys(languages);
        const index = keys.indexOf(i18n.locale.toUpperCase());
        const language = keys[(index + 1) % keys.length];

        Vue.axios.put('api/v1/me', {
            default_lang: languages[language]
        }).then(() => {
            i18n.locale = language.toLowerCase();
            state.language = language.toLowerCase();
        });
    },
};

const actions = {
    setLanguage(context, value) {
        context.commit('setLanguage', value);
    },
    changeLanguage(context) {
        context.commit('changeLanguage');
    },
};

export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations
};
