<template>
  <div class="fileUploadContainer">
    <div class="fileUpload" v-if="!document">
      <div class="fileUpload__input" @click="trigger">
        <div v-if="selectedFile">
          <div class="textOverflow">{{ selectedFile.name }}</div>
        </div>
        <div v-else class="textOverflow" style="color:#8d999f;">{{ placeholder }}</div>
        <input ref="uploader" type="file" accept="image/*, application/pdf" @change="file" id="importedDocument">
      </div>
      <div class="fileUpload__upload">
        <v-btn :disabled="!selectedFile" @click="upload">{{ $t('upload') }}</v-btn>
      </div>
    </div>
    <div class="fileUpload fileUpload--uploaded" v-else>
      <div class="fileUpload__input">
        <div class="frs">
          <v-icon color="dark-red" class="mr-2" size="25">icon-file</v-icon>
          <div class="textOverflow filename-link" @click="download">{{ document.name }}</div>
        </div>
      </div>
      <div class="fileUpload__upload">
        <v-tooltip bottom>
          <template v-slot:activator="{ on, attrs }">
            <v-badge color="success" v-if="document.verified_at !== null">
              <span slot="badge">{{ $t('verified') }}</span>
            </v-badge>
            <v-btn icon reset v-bind="attrs" v-on="on" @click="remove" v-else>
              <v-icon color="red" size="22">icon-trash</v-icon>
            </v-btn>
          </template>
          <span>{{ $t('delete') }}</span>
        </v-tooltip>
      </div>
    </div>
  </div>
</template>
<script>
import documentService from "../services/documentService";
import userService from "../services/userService";

export default {
  name: 'FileUpload',
  props: {
    type: {
      type: String,
      default: null
    },
    document: {
      type: Object,
      default: null
    },
    memberId: {
      type: Number,
      default: -1
    }
  },
  watch: {},
  computed: {
    user() {
      return this.$store.getters['user/user'];
    },
    placeholder() {
      return this.$t('choose_file') + ' - .pdf, .jpg ...';
    }
  },
  data: () => ({
    selectedFile: null,
    validFileTypes: [
      'image/jpeg', 'application/pdf', 'image/png'
    ],
  }),
  methods: {
    trigger() {
      this.isSelecting = true
      window.addEventListener('focus', () => {
        this.isSelecting = false
      }, {once: true})

      this.$refs.uploader.click()
    },
    file(e) {
      if (!this.validFileTypes.includes(e.target.files[0].type)) {
        this.$store.dispatch('alert/toggleShow', true);
        this.$store.dispatch('alert/setMessage', this.$t('incorrect_file_type'));
        return;
      }

      this.selectedFile = e.target.files[0];
    },
    upload() {
      if (this.type === null) {
        return;
      }

      let formData = new FormData();
      formData.append('file', this.selectedFile);
      formData.append('name', this.selectedFile.name);
      formData.append('type', this.type);

      documentService.upload(this.memberId, formData)
        .then(() => {
          userService.verify().then(() => {
            this.$emit('fileUploaded');
          });
          this.document = null;
        }).catch(() => {
        this.selectedFile = null;
        document.getElementById('importedDocument').value = '';
        this.$store.dispatch('alert/toggleShow', true);
        this.$store.dispatch('alert/setMessage', this.$t('upload_file_to_large'));
        this.$store.dispatch('loader/toggleShow', false);
      });
    },
    remove() {
      documentService.delete(this.document.id).then(() => {
        userService.verify().then(() => {
          this.$emit('fileUploaded');
        });
      });
      this.selectedFile = null;
    },
    download() {
      documentService.download(this.document.id)
        .then(data => {
          var blob = new Blob([data]);
          var link = document.createElement('a');
          link.href = window.URL.createObjectURL(blob);
          link.download = this.document.name;
          link.click();
        });
    }
  }
};
</script>

<style lang="scss">
.filename-link:hover {
  cursor: pointer;
  text-decoration: underline;
}
</style>
