<template>
  <Dashboard class="dashboard--user">
    <!--    <v-btn class="mb-5" @click="editMode = !editMode">{{ $t('change') }}</v-btn>-->
    <div class="card">

      <!-------------------------->

      <v-form>

        <v-row class="row--border row--br row--responsive">
          <v-col>

            <v-row dense class="align-center row--br">
              <v-col style="max-width:180px;" class="text-right"><label>{{ $t('first_name') }}</label></v-col>
              <v-col class="pl-6">
                <v-text-field outlined
                              hide-details
                              v-model="user.main_member.first_name"
                              disabled>
                </v-text-field>
              </v-col>
            </v-row>
            <v-row dense class="align-center row--br">
              <v-col style="max-width:180px;" class="text-right"><label>{{ $t('last_name') }}</label></v-col>
              <v-col class="pl-6">
                <v-text-field outlined
                              hide-details
                              v-model="user.main_member.last_name"
                              disabled></v-text-field>
              </v-col>
            </v-row>
            <v-row dense class="align-center row--br">
              <v-col style="max-width:180px;" class="text-right"><label>{{ $t('email') }}</label></v-col>
              <v-col class="pl-6">
                <v-text-field outlined hide-details
                              v-model="user.main_member.email"
                              disabled></v-text-field>
              </v-col>
            </v-row>
            <v-row dense class="align-center row--br" v-for="(phoneNumber, index) in user.main_member.phone_numbers"
                   :key="index">
              <v-col style="max-width:180px;" class="text-right">
                <phone-type v-model="phoneNumber.phone_type" disabled></phone-type>
              </v-col>

              <v-col class="pl-6">
                <v-text-field v-model="phoneNumber.phone_number" outlined hide-details
                              @change="updateNumber(phoneNumber)"
                              :placeholder="$t('phone_number')"
                              disabled
                ></v-text-field>
              </v-col>
            </v-row>
            <!--            <v-row dense class="align-center row&#45;&#45;br">-->
            <!--              <v-col style="max-width:180px;" class="text-right"></v-col>-->
            <!--              <v-col class="pl-6">-->
            <!--                <v-btn @click="addPhoneNumber">-->
            <!--                  <div class="iconSq iconSq&#45;&#45;sm iconSq&#45;&#45;border gradient mr-4 ml-n4">-->
            <!--                    <v-icon color="white" size="22">icon-plus</v-icon>-->
            <!--                  </div>-->
            <!--                  <div class="flex-grow-1 text-left">{{ $t('addNumber') }}</div>-->
            <!--                </v-btn>-->
            <!--              </v-col>-->
            <!--            </v-row>-->

            <!--            <v-row dense class="align-center row&#45;&#45;br">-->
            <!--              <v-col style="max-width:180px;" class="text-right"><label>{{ $t('password') }}</label></v-col>-->
            <!--              <v-col class="pl-6">-->
            <!--                <v-text-field outlined hide-details value="**********"></v-text-field>-->
            <!--              </v-col>-->
            <!--            </v-row>-->
            <v-row dense class="align-center row--br">
              <v-col style="max-width:180px;" class="text-right">&nbsp;</v-col>
              <v-col class="pl-6">
                <v-btn @click="showChangePasswordFields">{{ $t('change_password') }}</v-btn>
              </v-col>
              <v-col class="pl-6">
                <v-btn @click="showEmailChangeFields">{{ $t('change_email') }}</v-btn>
              </v-col>
            </v-row>
            <template v-if="changeEmail">
              <v-row dense class="align-center row--br">
                <v-col style="max-width:180px;" class="text-right"><label>{{ $t('new_email') }}</label></v-col>
                <v-col class="pl-6">
                  <v-text-field
                    ref="newEmail"
                    outlined
                    hide-details
                    :rules="[rules.email, rules.required]"
                    :placeholder="$t('new_email')"
                    v-model="newEmail"
                  ></v-text-field>
                </v-col>
              </v-row>
              <v-row dense class="align-center row--br">
                <v-col style="max-width:180px;" class="text-right">&nbsp;</v-col>
                <v-col class="pl-6">
                  <v-btn @click="updateEmail">{{ $t('update') }}</v-btn>
                </v-col>
              </v-row>

              <!--              <v-row dense class="align-center row&#45;&#45;br">-->
              <!--                <v-col style="max-width:180px;" class="text-right">&nbsp;</v-col>-->
              <!--                <v-col class="pl-6">-->
              <!--                  <v-btn reset><span class="red&#45;&#45;text">Mitgliedsnummer vergessen?</span></v-btn>-->
              <!--                </v-col>-->
              <!--              </v-row>-->
            </template>
            <template v-if="showChangePassword">
              <v-form ref="changePassword">
                <v-row dense class="align-center row--br">
                  <v-col style="max-width:180px;" class="text-right"><label>{{ $t('your_password') }}</label></v-col>
                  <v-col class="pl-6">
                    <v-text-field
                      :rules="[rules.required]"
                      :append-icon="showPassword ? 'mdi-eye' : 'mdi-eye-off'"
                      @click:append="showPassword = !showPassword"
                      :type="showPassword ? 'text' : 'password'"
                      outlined
                      :placeholder="$t('your_password')"
                      v-model="password.currentPassword"
                    ></v-text-field>
                  </v-col>
                </v-row>
                <v-row dense class="align-center row--br">
                  <v-col style="max-width:180px;" class="text-right"><label>{{ $t('new_password') }}</label></v-col>
                  <v-col class="pl-6">
                    <v-text-field
                      :append-icon="showNewPassword ? 'mdi-eye' : 'mdi-eye-off'"
                      @click:append="showNewPassword = !showNewPassword"
                      :type="showNewPassword ? 'text' : 'password'"
                      :rules="[rules.required, rules.min, rules.max, rules.upperCase, rules.lowerCase, rules.number]"
                      counter
                      outlined
                      :placeholder="$t('new_password')"
                      v-model="password.newPassword">
                    </v-text-field>
                  </v-col>
                </v-row>
                <v-row dense class="align-center row--br">
                  <v-col style="max-width:180px;" class="text-right"><label>{{ $t('reply_password') }}</label></v-col>
                  <v-col class="pl-6">
                    <v-text-field
                      outlined
                      :append-icon="showConfirmation ? 'mdi-eye' : 'mdi-eye-off'"
                      @click:append="showConfirmation = !showConfirmation"
                      :type="showConfirmation ? 'text' : 'password'"
                      :rules="[rules.required, rules.min, rules.max, passwordConfirmationRule]"
                      counter
                      :placeholder="$t('reply_password')"
                      v-model="password.confirmPassword">
                    </v-text-field>
                  </v-col>
                </v-row>
              </v-form>
              <v-row dense class="align-center row--br">
                <v-col style="max-width:180px;" class="text-right">&nbsp;</v-col>
                <v-col class="pl-6">
                  <v-btn @click="updatePassword">{{ $t('save') }}</v-btn>
                </v-col>
              </v-row>

              <!--              <v-row dense class="align-center row&#45;&#45;br">-->
              <!--                <v-col style="max-width:180px;" class="text-right">&nbsp;</v-col>-->
              <!--                <v-col class="pl-6">-->
              <!--                  <v-btn reset><span class="red&#45;&#45;text">Mitgliedsnummer vergessen?</span></v-btn>-->
              <!--                </v-col>-->
              <!--              </v-row>-->
            </template>

          </v-col>
          <v-col>

            <v-row dense class="align-center row--br">
              <v-col style="flex:none; width:auto;">
                <label>{{ $t('nationality') }}</label>
              </v-col>
              <v-col class="d-flex pl-6">
                <v-select
                  disabled
                  :items="countries"
                  v-model="user.main_member.citizenship_id"
                  return-object
                  placeholder="..."
                  item-text="text"
                  item-value="id"
                  :menu-props="{ bottom: true, offsetY: true, contentClass:'v-select-dropdown' }"
                  hide-details
                  outlined
                  attach
                ></v-select>
              </v-col>
            </v-row>

            <!--            <v-checkbox-->
            <!--              :label="$t('registerPage.confirmPernamentResidence')"-->
            <!--              hide-details-->
            <!--              disabled-->
            <!--              v-model="user.main_member.permanent_residence"-->
            <!--              class="cb&#45;&#45;inv cb&#45;&#45;text-sm mt-6"-->
            <!--            ></v-checkbox>-->

            <div class="d-flex mt-10 el--responsive-1">
              <div class="pt-3" style="width:130px;"><label>{{ $t('needHelp') }}?</label></div>
              <div class="pl-6">
                <div>
                  <a :href="'tel:' + contacts.phoneNumber" class="iconBlock">
                    <div class="iconBlock__icon">
                      <v-icon size="50" color="dark-red">icon-phone</v-icon>
                    </div>
                    <div class="iconBlock__text">{{ contacts.phoneNumber }}</div>
                  </a>
                </div>
                <div class="mt-2">
                  <a :href="'mailto:' + contacts.email" class="iconBlock">
                    <div class="iconBlock__icon">
                      <v-icon size="48" color="dark-red">icon-envelope</v-icon>
                    </div>
                    <div class="iconBlock__text">{{ contacts.email }}</div>
                  </a>
                </div>
              </div>
            </div>

            <div class="d-flex align-center mt-8 el--responsive-1">
              <div style="width:130px;" class="text-right"><label>{{ $t('created_at') }}</label></div>
              <div class="pl-6"><span class="dark-red--text">{{ user.main_member.created_at | moment }}</span></div>
            </div>
            <div>
              <div class="d-flex align-center mt-1 el--responsive-1" v-if="user.main_member.active_from  === null">
                <div style="width:130px;" class="text-right"><label>{{ $t('active_from') }}</label></div>
                <div class="pl-6"><span class="dark-red--text">{{ $t('pending') }}</span></div>
              </div>
              <div class="d-flex align-center mt-1 el--responsive-1" v-else>
                <div style="width:130px;" class="text-right"><label>{{ $t('active_from') }}</label></div>
                <div class="pl-6"><span class="dark-red--text">{{ user.main_member.active_from }}</span></div>
              </div>
            </div>

            <div class="d-flex align-center mt-1 el--responsive-1" v-if="user.main_member.inactive_from !== null ">
              <div style="width:130px;" class="text-right"><label>{{ $t('inactive_from') }}</label></div>
              <div class="pl-6"><span class="dark-red--text">{{ user.main_member.inactive_from }}</span></div>
            </div>

            <!--            <div class="d-flex align-center mt-10 el&#45;&#45;responsive-1">-->
            <!--              <v-btn rounded>-->
            <!--                <v-icon>icon-power</v-icon>-->
            <!--                <span>{{ $t('delete_account') }}</span>-->
            <!--              </v-btn>-->
            <!--              <div class="ml-4 flex-grow-1">-->
            <!--                <v-select :items="select1" placeholder="Abmeldegrund"-->
            <!--                          :menu-props="{ bottom: true, offsetY: true, contentClass:'v-select-dropdown' }" hide-details-->
            <!--                          outlined></v-select>-->
            <!--              </div>-->
            <!--            </div>-->

          </v-col>
        </v-row>

      </v-form>

      <!-------------------------->

    </div>
    <div class="d-flex mt-5">
      <v-btn to="/news">{{ $t('navigationButtons.backToNews') }}</v-btn>
    </div>
    <confirm ref="confirm"></confirm>
  </Dashboard>
</template>
<script>
import Dashboard from '@/layouts/Dashboard.vue';
import moment from "moment";
import contacts from "../../config/contacts";
import PhoneType from "../../components/Fields/PhoneType";
import userService from "../../services/userService";
import Confirm from "../../components/Dialogs/Confirm";

export default {
  name: 'MyAccount',
  components: {
    PhoneType,
    Dashboard,
    Confirm
  },
  data: () => ({
    contacts,
    rules: {
      required: value => !!value || 'Required.',
      min: v => v.length >= 8 || 'Min 8 characters',
      max: v => v.length <= 51 || 'Max 50 characters',
      upperCase: v => /[A-Z]/.test(v) || 'At least one uppercase',
      lowerCase: v => /[a-z]/.test(v) || 'At least one lowercase',
      number: v => /[0-9]/.test(v) || 'At least one number',
      emailMatch: () => (`The email and password you entered don't match`),
      email: value => !value || /^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,3})+$/.test(value) || 'Invalid E-mail address'
    },
    showPassword: false,
    showNewPassword: false,
    showConfirmation: false,
    showChangePassword: false,
    editMode: true,
    select1: ['lorem ipsum 1', 'lorem ipsum 2', 'lorem ipsum 3'],
    password: {
      currentPassword: '',
      newPassword: '',
      confirmPassword: ''
    },
    changeEmail: false,
    newEmail: '',
    user: null
  }),
  filters: {
    moment(date) {
      if (date === null) {
        return null;
      }
      return moment(date).format('DD.MM.YYYY');
    }
  },
  computed: {
    countries() {
      return this.$store.getters['countries/translatedCountries'];
    },
    passwordConfirmationRule() {
      return () => (this.password.newPassword === this.password.confirmPassword) || 'Password must match'
    },
  },
  mounted() {
    userService.verify().then(() => {
      this.user = this.$store.getters['user/user'];
    });
  },
  methods: {
    addPhoneNumber() {
      this.user.main_member.phone_numbers.push({
        id: -1,
        phone_number: '',
        phone_type: 'private_mobile',
        is_main_number: 0
      });
    },
    updatePassword() {
      if (!this.$refs.changePassword.validate()) {
        return;
      }

      this.$refs.confirm.confirm('Are you sure you want to continue?')
        .then(() => {
          userService.changePassword({
            old_password: this.password.currentPassword,
            new_password: this.password.newPassword,
            confirmation: this.password.confirmPassword
          }).then(() => {
            userService.logout().then(() => {
              this.$store.dispatch('user/logout').then(() => {
                this.$router.push({name: 'Login'});
              });
            });
          });
        }).catch(() => {
        return;
      });
    },
    updateEmail() {
      this.$refs.confirm.confirm('Are you sure you want to continue?')
        .then(() => {
          if (!this.$refs.newEmail.validate()) {
            this.$store.dispatch('alert/toggleShow', true);
            this.$store.dispatch('alert/setMessage', this.$t('invalid_email'));
            return;
          }

          let data = {
            email: this.newEmail,
          };

          userService.update(this.user.main_member.id, data).then(() => {
            this.newEmail = '';
            this.changeEmail = false;
            userService.logout().then(() => {
              this.$store.dispatch('user/logout').then(() => {
                this.$router.push({name: 'Login'});
              });
            });
            return;
          });
        }).catch(() => {
        return;
      });
    },
    showEmailChangeFields() {
      this.changeEmail = !this.changeEmail;
      this.showChangePassword = false;
    },
    showChangePasswordFields() {
      this.showChangePassword = !this.showChangePassword;
      this.changeEmail = false;
    }
  },
};
</script>
<style lang="scss">
</style>
