const substituteTypes = [
    {
        "value": "administrator",
    },
    {
        "value": "lawyer",
    },
    {
        "value": "family_member",
    },
];

export default substituteTypes;
