export const documentTypes = {
    PASSPORT: 'passport',
    RESIDENCE_PERMIT: 'residence_permit',
    REGISTRATION_FORM: 'registration_form',
    OTHER: 'other',
    BIRTH_CERTIFICATE: 'birth_certificate',
    PROOF_OF_CITIZENSHIP: 'proof_of_citizenship',
    INTERNATIONAL_MARRIAGE_CERTIFICATE: 'international_marriage_certificate',
    FINAL_DIVORCE_DECREE: 'final_divorce_decree',
    DECEASE_SPOUSE_CERTIFICATE: 'deceased_spouse_certificate',
    DEATH_OTHER: 'death_other'
}
