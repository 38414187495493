const membershipBasis = [
    {
        value: 'spouse',
    },
    {
        value: 'child'
    },
    {
        value: 'child_with_disability'
    }
];

export default membershipBasis;
