<template>
  <v-app>
    <router-view/>
    <alert></alert>
    <RegisterInstructions></RegisterInstructions>
    <v-overlay z-index="500" :value="loader">
      <v-progress-circular indeterminate size="64"></v-progress-circular>
    </v-overlay>
  </v-app>
</template>

<script>
import Alert from "./components/Dialogs/Alert";
import userService from "./services/userService";
import RegisterInstructions from "./components/Dialogs/RegisterInstructions";

export default {
  components: {RegisterInstructions, Alert},
  name: 'App',
  data: () => ({}),
  computed: {
    loader() {
      return this.$store.getters["loader/show"];
    }
  },
  mounted() {
    userService.verify();
  }
};
</script>
