<template>
  <Dashboard class="dashboard--household">
    <div class="card">
      <!-------------------------->

      <v-form style="max-width:660px;" ref="household">
        <v-row dense class="align-center row--br">
          <v-col style="max-width:110px;" class="text-right">&nbsp;</v-col>
          <v-col class="pl-6">
            <div class="d-flex align-center justify-space-between">
              <h4 class="dark-red--text ma-0">{{ $t('menu.household') }}</h4>
            </div>
          </v-col>
        </v-row>
        <v-row dense class="align-center row--br">
          <v-col style="max-width:110px;" class="text-right"><label>{{ $t('street') }}</label></v-col>
          <v-col class="pl-6">
            <v-text-field v-model="address.street"
                          :rules="[rules.required]"
                          outlined
                          :placeholder="$t('street')"
                          @change="dirty=true"
            ></v-text-field>
          </v-col>
        </v-row>
        <v-row dense class="align-center row--br">
          <v-col style="max-width:110px;" class="text-right"><label>{{ $t('postal_code') }}</label></v-col>
          <v-col class="pl-6">
            <v-text-field
              :rules="[rules.required, rules.number]"
              min="1"
              v-model="address.postal_code"
              persistent-hint
              outlined
              @change="dirty=true"
              :placeholder="$t('postal_code')">
            </v-text-field>
          </v-col>
        </v-row>
        <v-row dense class="align-center row--br">
          <v-col style="max-width:110px;" class="text-right"><label>{{ $t('state') }}</label></v-col>
          <v-col class="pl-6">
            <v-text-field
              v-model="address.state"
              :rules="[rules.required]"
              outlined
              :placeholder="$t('state')"
              @change="dirty=true"
            ></v-text-field>
          </v-col>
        </v-row>
        <v-row dense class="align-center row--br">
          <v-col style="max-width:110px;" class="text-right"><label>{{ $t('city') }}</label></v-col>
          <v-col class="pl-6">
            <v-text-field
              v-model="address.city"
              outlined
              :placeholder="$t('city')"
              :rules="[rules.required]"
            ></v-text-field>
          </v-col>
        </v-row>
        <v-row dense class="align-center row--br">
          <v-col style="max-width:110px;" class="text-right"><label>{{ $t('country') }}</label></v-col>
          <v-col class="pl-6">
            <v-select
              v-model="address.country_id"
              :rules="[rules.required]"
              :placeholder="$t('country')"
              @change="dirty=true"
              outlined
              :items="countries"
              item-text="text"
              item-value="id"
              attach
            ></v-select>
          </v-col>
        </v-row>
      </v-form>

      <!-------------------------->
    </div>
    <div class="buttonContainer justify-space-between mt-3">
      <v-btn to="/main-member">
        <div class="iconSq iconSq--sm iconSq--border gradient">
          <v-icon color="white" size="22">icon-chevron-left</v-icon>
        </div>
        <div class="flex-grow-1 text-center">{{ $t('navigationButtons.backToMainMember') }}</div>
      </v-btn>
      <v-btn @click.prevent="save">
        <div class="flex-grow-1 text-center">{{ $t('navigationButtons.nextToSubmembers') }}</div>
        <div class="iconSq iconSq--sm iconSq--border gradient">
          <v-icon color="white" size="22">icon-chevron-right</v-icon>
        </div>
      </v-btn>
    </div>
    <Manual :step="1" :visible="showManual" @toggle="showManual = !showManual" v-if="!$existingMember"></Manual>
    <confirm ref="confirm"></confirm>
  </Dashboard>
</template>
<script>
import Dashboard from '@/layouts/Dashboard.vue';
import Manual from '@/components/Manual.vue';
import Confirm from "../../components/Dialogs/Confirm";
import addressService from "../../services/addressService";
import states from "../../config/states";
import userService from "../../services/userService";
import {addressTypes} from "../../enumerations/addressTypes";

export default {
  name: 'Household',
  components: {
    Dashboard,
    Manual,
    Confirm
  },
  data: () => ({
    states,
    rules: {
      required: value => !!value || 'Required.',
      max: value => value <= 999992 || 'To high',
      min: value => value >= 1000 || 'To low',
      number: value => /^[0-9]*$/.test(value) || 'Only numbers alowed',
    },
    address: {
      street: '',
      postal_code: '',
      state: '',
      city: '',
      country: ''
    },
    dirty: false,
    showManual: false
  }),
  computed: {
    user() {
      return this.$store.getters['user/user'];
    },
    countries() {
      return this.$store.getters['countries/translatedCountries'];
    },
  },
  mounted() {
    this.$store.dispatch('user/setStep', 2);

    if (this.user.main_member.addresses.length > 0) {
      this.address = JSON.parse(JSON.stringify(this.user.main_member.addresses.find(item => item.type === addressTypes.DOMESTIC && item.is_main_address)));
    }
  },
  methods: {
    save() {
      if (!this.$refs.household.validate()) {
        return;
      }

      let data = {
        "type": "domestic",
        "street": this.address.street,
        "postal_code": this.address.postal_code,
        "city": this.address.city,
        "country_id": this.address.country_id,
        "is_main_address": true,
        "state": this.address.state
      }

      if (this.address.id !== undefined) {
        addressService.update(this.address.id, data).then(() => {
          this.dirty = false;
          userService.verify();
          this.$router.push({name: 'sub-member'});
        });
        return;
      }

      addressService.store(this.user.main_member.id, data).then(() => {
        userService.verify();
        this.dirty = false;
        this.$router.push({name: 'sub-member'});
      });
    },
    confirm(message) {
      return this.$refs.confirm.confirm(message);
    },
  },
  beforeRouteLeave(to, from, next) {
    if (!this.dirty || this.$existingMember) {
      next();
      return;
    }

    this.confirm(this.$t('confirmUnsavedDataProceed'))
      .then(next)
      .catch(() => {
        next(false);
      });
  }
};
</script>
