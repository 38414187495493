import Vue from 'vue';

export default {
    async store(householdId, data) {
        const response = await Vue.axios.post('api/v1/household/' + householdId + '/substitute', data);
        return response;
    },
    async update(id, data) {
        const response = await Vue.axios.put('api/v1/household/substitute/' + id, data);
        return response;
    },
    async delete(id) {
        const response = await Vue.axios.delete('api/v1/household/substitute/' + id);
        return response;
    },
}
