import Vue from 'vue';

export default {
    async upload(memberId, data) {
        const response = await Vue.axios.post('/api/v1/member/' + memberId + '/document', data);
        return response;
    },
    async delete(id) {
        const response = await Vue.axios.delete('/api/v1/member/document/' + id);
        return response;
    },
    async download(id) {
        const response = await Vue.axios.get('/api/v1/member/document/' + id, {responseType: 'blob'});
        return response.data;
    },
}
