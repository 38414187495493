<template>
    <v-dialog
       :value="visible"
       :max-width="width"
       persistent
       :content-class="contentClass"
       >
       <v-card>
            <v-btn v-if="type != 'alert'" icon class="v-dialog__close" @click="dialogClose"><v-icon>icon-remove</v-icon></v-btn>
            <v-card-text>
                <slot name="body"></slot>
            </v-card-text>
            <v-card-actions v-if="hasActions">
                <div class="buttonContainer justify-center">
                    <slot name="actions"></slot>
                </div>
            </v-card-actions>
       </v-card>
    </v-dialog>
</template>
<script>
export default {
    name: 'Dialog',
    props:{
        visible: {
            default:false
        },
        width: {
            default:860
        },
        type: {
            default:''
        },
    },
    data() {
        return {
            hasActions:false
        }
    },
    computed: {
        contentClass:function(){
            if(this.type == 'alert')
                return 'v-dialog--alert';
            else if(this.type == 'receipt')
                return 'v-dialog--receipt';
            else
                return this.type;
        }
    },
    mounted() {
        if(this.$slots.actions) this.hasActions = true;
    },
    methods: {
        dialogClose(){
            this.$emit('update:visible', false);
            this.$emit('close');
        }
    },
}
</script>
