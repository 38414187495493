<template>
  <Dashboard class="dashboard--payment">
    <div class="card">
      <!------------------------------>

      <h4 class="dark-red--text mb-4">{{ $t('new_payment') }}</h4>

      <v-expansion-panels class="accordion accordion--payments ml-n5">
        <v-expansion-panel
          v-for="(item,index) in payments"
          :key="index"
        >
          <v-expansion-panel-header>
            <v-row>
              <v-col style="min-width:250px; max-width:250px;"><strong class="red--text">{{ item.title }}</strong>
              </v-col>
              <v-col><strong class="red--text">{{ item.invoice }}</strong></v-col>
              <v-col><strong class="red--text" v-if="item.sale_invoice">
                {{ $t('due_date') }}:
                {{ item.sale_invoice.due_date }}</strong></v-col>
              <v-col>
                <v-badge :color="getStatusColor(item.state)">
                    <span slot="badge">
                    <strong>
                      {{ $t(`invoice_statuses.${item.state}`).toUpperCase() }}
                    </strong>
                    </span>
                </v-badge>
              </v-col>
              <v-col style="max-width:150px; max-height:50px; margin: auto">
                <v-btn v-if="item.status_id === 1" @click.prevent.stop="openDetails(item)" style="max-height: 30px">
                  {{ $t('pay_now') }}
                </v-btn>
                <v-btn
                  v-else-if="item.open_item"
                  hide-details
                  style="max-height: 30px"
                  @click.prevent.stop="pay"
                >
                  {{ $t('pay_now') }}
                </v-btn>
              </v-col>
              <v-col style="max-width:140px;" class="text-right"><strong class="red--text">€ {{ item.price }}</strong>
              </v-col>
            </v-row>
          </v-expansion-panel-header>
          <v-expansion-panel-content>
            <v-row v-for="(member, index) in item.members" :key="index">
              <v-col style="min-width:250px; max-width:250px;">{{ member.description }}</v-col>
              <v-col>{{ member.name }}</v-col>
              <v-col class="text-right">€ {{ member.price }}</v-col>
            </v-row>
          </v-expansion-panel-content>
        </v-expansion-panel>
      </v-expansion-panels>
    </div>

    <div class="card">
      <h4 class="dark-red--text mb-4">{{ $t('pending_payment') }}</h4>
      <div v-if="pendingPayments.length > 0">
        <label>{{ $t('pending_payment_info') }}</label>
        <br>
        <label><strong>{{ $t('pending_payment_warn') }}</strong></label>
      </div>
      <v-expansion-panels class="accordion accordion--payments ml-n5" style="margin-top: 10px">
        <v-expansion-panel
          v-for="(item,index) in pendingPayments"
          :key="index"
        >
          <v-expansion-panel-header>
            <v-row>
              <v-col style="min-width:250px; max-width:250px;"><strong class="red--text">{{ item.title }}</strong>
              </v-col>
              <v-col><strong class="red--text">{{ item.invoice }}</strong></v-col>
              <v-col>
                <strong class="red--text">{{ $t('due_date') }}: {{ item.sale_invoice.due_date }}
                </strong>
                <br>
                <strong class="red--text" v-if="item.sale_invoice.dunning_status > 0">{{ $t('dunning_status') }}:
                  {{ $t(`invoice_dunning_statuses.${item.sale_invoice.dunning_status}`) }}
                </strong>
              </v-col>
              <v-col style="max-width:270px;">
                <v-badge :color="getStatusColor(item.state)">
                  <span slot="badge">
                    <strong>
                      {{ $t(`invoice_statuses.${item.state}`).toUpperCase() }}
                    </strong>
                  </span>
                </v-badge>
                <br>
              </v-col>
              <v-col style="max-width:150px;">
                <v-icon color="red" @click.prevent="renderPdf(item)">mdi-download-circle-outline</v-icon>
              </v-col>
              <v-col style="max-width:140px;" class="text-right"><strong class="red--text">€ {{ item.price }}</strong>
              </v-col>
            </v-row>
          </v-expansion-panel-header>
          <v-expansion-panel-content>
            <v-row v-for="(member, index) in item.members" :key="index">
              <v-col style="min-width:250px; max-width:250px;">{{ member.description }}</v-col>
              <v-col>{{ member.name }}</v-col>
              <v-col class="text-right">€ {{ member.price }}</v-col>
            </v-row>
          </v-expansion-panel-content>
        </v-expansion-panel>
      </v-expansion-panels>
    </div>

    <div class="card">
      <h4 class="dark-red--text mb-4">{{ $t('payment_history') }}</h4>

      <v-expansion-panels class="accordion accordion--payments ml-n5">
        <v-expansion-panel
          v-for="(item,index) in paymentHistory"
          :key="index"
        >
          <v-expansion-panel-header>
            <v-row>
              <v-col style="min-width:250px; max-width:250px;"><strong class="red--text">{{ item.title }}</strong>
              </v-col>
              <v-col><strong class="red--text">{{ item.invoice }}</strong></v-col>
              <v-col>
                <strong class="red--text">
                  {{ $t('due_date') }}: {{ item.sale_invoice.due_date }}
                </strong>
              </v-col>
              <v-col style="max-width:270px;">
                <v-badge :color="getStatusColor(item.state)">
                  <span slot="badge">
                    <strong>
                      {{ $t(`invoice_statuses.${item.state}`).toUpperCase() }}
                    </strong>
                  </span>
                </v-badge>
              </v-col>
              <v-col style="max-width:150px;">
              </v-col>
              <v-col style="max-width:140px;" class="text-right"><strong class="red--text">€ {{ item.price }}</strong>
              </v-col>
            </v-row>
          </v-expansion-panel-header>
          <v-expansion-panel-content>
            <v-row v-for="(member, index) in item.members" :key="index">
              <v-col style="min-width:250px; max-width:250px;">{{ member.description }}</v-col>
              <v-col>{{ member.name }}</v-col>
              <v-col class="text-right">€ {{ member.price }}</v-col>
            </v-row>
          </v-expansion-panel-content>
        </v-expansion-panel>
      </v-expansion-panels>
    </div>

    <div class="buttonContainer mt-3">
      <v-btn to="/substitutes" v-if="$existingMember">
        <div class="iconSq iconSq--sm iconSq--border gradient">
          <v-icon color="white" size="22">icon-chevron-left</v-icon>
        </div>
        <div class="flex-grow-1 text-center">{{ $t('navigationButtons.backToSubstitutes') }}</div>
      </v-btn>
      <v-btn to="/sub-member" v-else>
        <div class="iconSq iconSq--sm iconSq--border gradient">
          <v-icon color="white" size="22">icon-chevron-left</v-icon>
        </div>
        <div class="flex-grow-1 text-center">{{ $t('navigationButtons.backToSubmembers') }}</div>
      </v-btn>
    </div>

    <!---------------------------------------------------------->

    <app-dialog :visible.sync="receiptShown" type="receipt" v-if="receiptShown" @close="cancelInvoice">
      <template v-slot:body>
        <Receipt :items="invoice" :total="invoice[0].debit">
          <template slot="actions">
            <div class="buttonContainer justify-end">
              <v-btn @click="cancelInvoice">{{ $t('cancel') }}</v-btn>
              <v-btn @click="confirmInvoice">{{ $t('confirm') }}</v-btn>
            </div>
          </template>
        </Receipt>
      </template>
    </app-dialog>

    <!---------------------------------------------------------->

    <Manual :step="3" :visible="showManual" @toggle="showManual = !showManual" v-if="!$existingMember"></Manual>

  </Dashboard>
</template>
<script>
import Dashboard from '@/layouts/Dashboard.vue';
import Manual from '@/components/Manual.vue';
// import Dc from '@/components/DoubleCircleIcon.vue';
import AppDialog from '@/components/Dialog.vue';
import Receipt from '@/components/Receipt.vue';
import paymentService from "../../services/paymentService";
import moment from "moment";

export default {
  name: 'Payment',
  components: {
    Dashboard,
    Manual,
    // Dc,
    AppDialog,
    Receipt
  },
  data: () => ({
    showManual: false,
    collapse: false,

    receiptShown: false,

    payments: [],
    selectedInvoices: [],
    invoice: null,
    pendingPayments: [],
    paymentHistory: []
  }),
  computed: {
    user() {
      return this.$store.getters['user/user'];
    },
  },
  filters: {
    moment(date) {
      if (date === null) {
        return null;
      }
      return moment(date).format('DD.MM.YYYY');
    }
  },
  mounted() {
    this.$store.dispatch('user/setStep', 4);
    this.getFinanceJournal();
    this.getPaymentHistory();
    this.getPendingPayments();
  },
  methods: {
    getFinanceJournal() {
      paymentService.precalculation()
        .then(res => {
          this.payments = [];
          if (res.registration_fee !== undefined) {
            this.payments.push({
              status_id: null,
              id: null,
              open_item: res.registration_fee.available,
              title: res.registration_fee.description,
              invoice: '',
              state: 1,
              isChecked: res.registration_fee.available,
              price: res.registration_fee.amount,
              members: []
            });
          }
          if (res.annual_contribution !== undefined) {
            this.payments.push({
              status_id: null,
              id: null,
              open_item: res.annual_contribution.available,
              title: res.annual_contribution.description,
              invoice: '',
              state: 1,
              isChecked: res.annual_contribution.available,
              price: res.annual_contribution.total,
              members: res.annual_contribution.detail.map(item => {
                return {
                  title: '',
                  description: this.$t('submember'),
                  name: item.first_name + ' ' + item.last_name,
                  price: item.amount
                }
              })
            });
          }
          this.financialJournal();
        });
    },
    tableCollapse() {
      this.collapse = !this.collapse;
    },
    tableExpand(e) {
      e.item.seen = true;
    },
    pay() {
      paymentService.createInvoice()
        .then(res => {
          if (res.length === 0) {
            this.$store.dispatch('alert/toggleShow', true);
            this.$store.dispatch('alert/setMessage', 'No payment to pay');
            return;
          }
          this.invoice = res;
          this.receiptShown = true;
        });
    },
    financialJournal() {
      let params = [];
      params['filters[sale_invoice.status_id][1]'] = '1';
      params['filters[type][0]'] = 'siac';
      params['filters[type][1]'] = 'sirf';
      params['filters[type][2]'] = 'sim';
      params['orderBy[sale_invoice.due_date]'] = 'DESC';
      paymentService.financialJournal(this.user.household.id, params)
        .then(res => {
          res.data.forEach(item => {
            this.payments.push({
              id: item.sale_invoice.id,
              status_id: item.sale_invoice.status_id,
              open_item: item.open_item,
              title: item.sale_invoice.description,
              invoice: '',
              state: item.sale_invoice.status_id,
              isChecked: false,
              price: item.sale_invoice.amount,
              type: item.type,
              members: item.sale_invoice.sale_invoice_items.map(el => {
                return {
                  title: '',
                  name: el.member === null ? '' : el.member.first_name + ' ' + el.member.last_name,
                  description: el.description,
                  price: el.amount
                }
              }),
              sale_invoice: item.sale_invoice,
              sale_invoice_id: item.sale_invoice.id,
              debit: item.debit
            });
          });
        });
    },
    confirmInvoice() {
      if (this.invoice[0].sale_invoice_id !== null) {
        paymentService.confirmInvoice(this.invoice[0].sale_invoice_id)
          .then(() => {
            this.renderPdf(this.invoice[0]);
            this.getFinanceJournal();
            this.getPendingPayments();
            this.getPaymentHistory();
            this.receiptShown = false;
          });
        return;
      }
    },
    cancelInvoice() {
      if (this.invoice[0].sale_invoice_id !== null) {
        paymentService.cancelInvoice(this.invoice[0].sale_invoice_id)
          .then(() => {
            this.getFinanceJournal();
            this.getPendingPayments();
            this.getPaymentHistory();
            this.receiptShown = false;
          });
      }
    },
    getFilenameForPdf(item) {
      let invoiceDescription = this.$t('annual_contribution_pdf') + ' ' + item.sale_invoice.description.slice(-4);
      const infix = '_ATIB_';
      const sufix = '.pdf';

      if (item.type === 'sirf') {
        invoiceDescription = this.$t('registration_fee_pdf');
      }

      if (item.sale_invoice.description === "Anmeldegebühr") {
        invoiceDescription = this.$t('registration_fee_pdf')
      }

      return item.sale_invoice.household_id + infix + invoiceDescription + '_' + item.sale_invoice.id + sufix;
    },
    renderPdf(item) {
      paymentService.renderPdf(item.sale_invoice.id)
        .then(data => {
          var blob = new Blob([data]);
          var link = document.createElement('a');
          link.href = window.URL.createObjectURL(blob);
          link.download = this.getFilenameForPdf(item);
          link.click();
        });
      return;
    },
    getPendingPayments() {
      let params = [];
      params['filters[sale_invoice.status_id][1]'] = '2';
      params['filters[sale_invoice.status_id][2]'] = '6';
      params['filters[sale_invoice.status_id][3]'] = '7';
      params['filters[type][0]'] = 'siac';
      params['filters[type][1]'] = 'sirf';
      params['filters[type][2]'] = 'sim';
      params['orderBy[sale_invoice.due_date]'] = 'DESC';
      paymentService.financialJournal(this.user.household.id, params)
        .then(res => {
          this.pendingPayments = [];
          res.data.forEach(item => {
            this.pendingPayments.push({
              id: item.sale_invoice.id,
              open_item: item.open_item,
              title: item.sale_invoice.description,
              invoice: '',
              state: item.sale_invoice.status_id,
              isChecked: false,
              price: item.sale_invoice.amount,
              members: item.sale_invoice.sale_invoice_items.map(el => {
                return {
                  title: '',
                  name: el.member === null ? '' : el.member.first_name + ' ' + el.member.last_name,
                  description: el.description,
                  price: el.amount
                }
              }),
              sale_invoice: item.sale_invoice,
            });
          });
        });
    },
    getPaymentHistory() {
      let params = [];
      params['filters[sale_invoice.status_id][1]'] = '3';
      params['filters[sale_invoice.status_id][2]'] = '4';
      params['filters[sale_invoice.status_id][3]'] = '5';
      params['filters[sale_invoice.status_id][4]'] = '8';
      params['filters[type][0]'] = 'siac';
      params['filters[type][1]'] = 'sirf';
      params['filters[type][2]'] = 'sim';
      params['orderBy[sale_invoice.due_date]'] = 'DESC';
      paymentService.financialJournal(this.user.household.id, params)
        .then(res => {
          this.paymentHistory = [];
          res.data.forEach(item => {
            this.paymentHistory.push({
              id: item.sale_invoice.id,
              open_item: item.open_item,
              title: item.sale_invoice.description,
              invoice: '',
              state: item.sale_invoice.status_id,
              isChecked: false,
              price: item.sale_invoice.amount,
              members: item.sale_invoice.sale_invoice_items.map(el => {
                return {
                  title: '',
                  name: el.member === null ? '' : el.member.first_name + ' ' + el.member.last_name,
                  description: el.description,
                  price: el.amount
                }
              }),
              sale_invoice: item.sale_invoice,
            });
          });
        });
    },
    openDetails(item) {
      this.invoice = [item];
      this.receiptShown = true;
    },
    getStatusColor(status) {
      let color = 'red';
      switch (status) {
        case 1:
          color = 'light-blue';
          break;
        case 2:
          color = 'orange';
          break;
        case 3:
          color = 'green';
          break;
        case 4:
          color = 'red';
          break;
        case 5:
          color = 'red';
          break;
        case 6:
          color = 'green';
          break;
        case 7:
          color = 'orange';
          break;
        case 8:
          color = 'red';
          break;
        default:
          color = 'green';
          break
      }
      return color;
    }
  },
};
</script>
