<template>
  <v-menu
    v-model="datePicker1"
    :close-on-content-click="false"
    :nudge-right="0"
    transition="scale-transition"
    offset-y
    min-width="280px"
    attach=".col"
    ref="dateOfBirth"
    :disabled="disableFields"
  >
    <template v-slot:activator="{ on, attrs }">
      <v-text-field
        :rules="[rules.required]"
        v-model="date"
        @blur="checkDate"
        outlined
        :placeholder="placeholder + ' (DD.MM.YYYY)'"
        append-icon="icon-calendar"
        v-bind="attrs"
        v-on="on"
        class="v-input--datepicker"
        :disabled="disableFields"
      ></v-text-field>
    </template>
    <v-date-picker
      v-bind:value="value"
      @change="updateValue"
      @input="datePicker1 = false"
      :max="max"
      :min="min"
      color="red"
      format="DD.MM.YYYY"
    ></v-date-picker>
  </v-menu>
</template>

<script>
import moment from "moment";

export default {
  name: "DatePicker",
  props: {
    value: {
      type: String,
      default: null
    },
    disableFields: {
      type: Boolean,
      default: false
    },
    min: {
      type: String,
      default: null
    },
    max: {
      type: String,
      default: null
    },
    placeholder: {
      type: String,
      default: null
    }
  },
  data: () => ({
    rules: {
      required: value => !!value || 'Required.',
    },
    datePicker1: false,
    date: null
  }),
  computed: {
    displayedDate() {
      if (this.value === null) {
        return null;
      }
      if (this.value.includes('.')) {
        this.updateValue(moment(this.value, 'DD.MM.YYYY').format('YYYY-MM-DD'));
        return;
      }
      return moment(this.value).format('DD.MM.YYYY');
    }
  },
  watch: {
    displayedDate(val) {
      this.date = val;
    }
  },
  mounted() {
    this.date = this.displayedDate;
  },
  methods: {
    validateDateOfBirth(value) {
      if (value === null || value === '' || value.length < 10) {
        return false;
      }

      if (moment(value, 'DD.MM.YYYY').isBefore(moment())) {
        return true;
      }

      return false;
    },
    updateValue: function (value) {
      this.$emit('input', value);
    },
    checkDate() {
      if (moment(this.date, 'DD.MM.YYYY').isBefore(moment())) {
        this.$emit('input', this.date);
        return;
      }
      this.date = null;
    }
  }
}
</script>

<style scoped>

</style>
