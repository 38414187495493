import Vue from 'vue';
import VueI18n from 'vue-i18n';

Vue.use(VueI18n);

import en from '../i18n/en.json';
import de from '../i18n/de.json';
import tr from '../i18n/tr.json';

let locale = 'de';

function loadLocaleMessages() {
    const messages = {
        en,
        de,
        tr
    };
    return messages;
}

const i18n = new VueI18n({
    locale: locale,
    fallbackLocale: locale,
    messages: loadLocaleMessages()
});
export default i18n;
