import {documentTypes} from "../enumerations/documentTypes";
import {addressTypes} from "../enumerations/addressTypes";
import Vue from "vue";
import Router from "../router";

const state = {
    user: null,
    step: 0,
    loggedIn: false
};

const getters = {
    user(state) {
        if (state.user) {
            Vue.prototype.$existingMember = state.user.main_member.status === 'waiting' || state.user.main_member.status === 'active';
        }
        return state.user
    },
    passport(state) {
        return state.user.main_member.documents.find(item => item.type === documentTypes.PASSPORT);
    },
    burialPlace(state) {
        return state.user.main_member.addresses.find(item => item.type === addressTypes.BURIAL_PLACE);
    },
    step(state) {
        return state.step;
    },
    isLoggedIn(state) {
        return state.user !== null;
    }
};

const mutations = {
    setUser(state, value) {
        state.user = value;
        state.loggedIn = true;
    },
    setStep(state, value) {
        state.step = value;
    },
    logout(state) {
        state.user = null;
        state.step = 0;
        state.loggedIn = false;
        Router.push({name: 'Login'})
    },
    addMainMemberPhoneNumber(state) {
        state.user.main_member.phone_numbers.push({
            id: -1,
            phone_number: '',
            phone_type: 'private_mobile',
            is_main_number: false
        });
    },
};

const actions = {
    setUser(context, value) {
        context.commit('setUser', value);
    },
    setStep(context, value) {
        context.commit('setStep', value);
    },
    logout(context) {
        context.commit('logout');
    },
    addMainMemberPhoneNumber(context) {
        context.commit('addMainMemberPhoneNumber');
    },
};

export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations
};
