<template>
  <Dashboard class="dashboard--household">
    <div class="card" v-if="user">
      <!-------------------------->

      <v-form ref="mainMember">
        <div style="max-width:760px;">

          <v-row dense class="align-center row--br">
            <v-col style="max-width:180px;" class="text-right">&nbsp;</v-col>
            <v-col class="pl-6">
              <div class="d-flex align-center justify-space-between">
                <h4 class="dark-red--text ma-0">{{ $t('personal_data') }}</h4>
              </div>
            </v-col>
          </v-row>
          <v-row dense class="align-center row--br">
            <v-col style="max-width:180px;" class="text-right"><label>{{ $t('first_name') }}</label></v-col>
            <v-col class="pl-6">
              <v-text-field outlined hide-details :placeholder="$t('first_name')"
                            :rules="[rules.required]"
                            :disabled="$existingMember"
                            v-model="user.main_member.first_name"></v-text-field>
            </v-col>
          </v-row>
          <v-row dense class="align-center row--br">
            <v-col style="max-width:180px;" class="text-right"><label>{{ $t('last_name') }}</label></v-col>
            <v-col class="pl-6">
              <v-text-field outlined hide-details :placeholder="$t('last_name')"
                            :rules="[rules.required]"
                            :disabled="$existingMember"
                            v-model="user.main_member.last_name"></v-text-field>
            </v-col>
          </v-row>
          <v-row dense class="align-center row--br">
            <v-col style="max-width:180px;" class="text-right"><label>{{ $t('date_of_birth') }}</label></v-col>
            <v-col class="pl-6">
              <date-picker
                :max="today"
                v-model="user.main_member.date_of_birth"
                :disable-fields="$existingMember"
                :placeholder="$t('date_of_birth')"></date-picker>
            </v-col>
          </v-row>

          <v-row dense class="align-center row--br">
            <v-col style="max-width:180px;" class="text-right"><label>{{ $t('social_insurance_number') }}</label>
            </v-col>
            <v-col class="pl-6">
              <v-text-field outlined :placeholder="$t('social_insurance_number')"
                            :rules="[rules.required, rules.number]"
                            v-model="user.main_member.social_insurance_number"
                            :disabled="$existingMember"
              ></v-text-field>
            </v-col>
          </v-row>
          <v-row dense class="align-center row--br">
            <v-col style="max-width:180px;" class="text-right"><label>{{ $t('citizenship') }}</label></v-col>
            <v-col class="pl-6">
              <v-select
                v-model="user.main_member.citizenship_id"
                :rules="[rules.required]"
                :disabled="$existingMember"
                outlined
                :items="countries"
                item-text="text"
                item-value="id"
                attach
                :placeholder="$t('citizenship')"
              ></v-select>
            </v-col>
          </v-row>
          <v-row dense class="align-center row--br">
            <v-col style="max-width:180px;">
            </v-col>
            <v-col class="pl-6">
              <v-checkbox
                :disabled="$existingMember"
                :rules="[rules.checkboxRequired]"
                v-model="user.main_member.permanent_residence"
                :label="$t('member_has_pernament_residence')"
              ></v-checkbox>
            </v-col>
          </v-row>
          <v-row dense class="align-center row--br">
            <v-col style="max-width:180px;" class="text-right"><label>{{ $t('turkish_identity_number') }}</label>
            </v-col>
            <v-col class="pl-6">
              <v-text-field outlined
                            :placeholder="$t('turkish_identity_number')"
                            :rules="[validateTurkishIdentityNumber]"
                            v-model="user.main_member.turkish_id_no"
              >
              </v-text-field>
            </v-col>
          </v-row>
          <v-row dense class="align-center row--br">
            <v-col style="max-width:180px;" class="text-right"><label>{{ $t('place_of_birth') }}</label></v-col>
            <v-col class="pl-6">
              <v-text-field outlined hide-details
                            :placeholder="$t('place_of_birth')"
                            :rules="[rules.required]"
                            v-model="user.main_member.place_of_birth"
                            :disabled="$existingMember"
              >
              </v-text-field>
            </v-col>
          </v-row>
          <v-row dense class="align-center row--br">
            <v-col style="max-width:180px;" class="text-right"><label>{{ $t('gender') }}</label></v-col>
            <v-col class="pl-6">
              <Gender v-model="user.main_member.gender" :disabled="$existingMember"></Gender>
            </v-col>
          </v-row>
          <v-row dense class="align-center row--br">
            <v-col style="max-width:180px;" class="text-right"><label>{{ $t('civil_status') }}</label></v-col>
            <v-col class="pl-6">
              <civil-status v-model="user.main_member.civil_status" :disabled="$existingMember"
                            :items="civilStatuses"></civil-status>
            </v-col>
          </v-row>
          <v-row dense class="align-center row--br">
            <v-col style="max-width:180px;" class="text-right">&nbsp;</v-col>
            <v-col class="pl-6">
              <div class="d-flex align-center justify-space-between">
                <h4 class="dark-red--text ma-0">{{ $t('family_relationship') }}</h4>
              </div>
            </v-col>
          </v-row>
          <v-row dense class="align-center row--br">
            <v-col style="max-width:180px;" class="text-right"><label>{{ $t('name_father') }}</label></v-col>
            <v-col class="pl-6">
              <v-text-field v-model="user.main_member.father_name" outlined
                            :rules="[rules.required]"
                            :disabled="$existingMember"
                            :placeholder="$t('name_father')"></v-text-field>
            </v-col>
          </v-row>
          <v-row dense class="align-center row--br">
            <v-col style="max-width:180px;" class="text-right"><label>{{ $t('name_mother') }}</label></v-col>
            <v-col class="pl-6">
              <v-text-field v-model="user.main_member.mother_name" outlined
                            :rules="[rules.required]"
                            :disabled="$existingMember"
                            :placeholder="$t('name_mother')"></v-text-field>
            </v-col>
          </v-row>
          <v-row dense class="align-center row--br">
            <v-col style="max-width:180px;" class="text-right">&nbsp;</v-col>
            <v-col class="pl-6">
              <div class="d-flex align-center justify-space-between">
                <h4 class="dark-red--text ma-0">{{ $t('phone_number') }}</h4>
              </div>
            </v-col>
          </v-row>
          <v-row dense class="align-center row--br"
                 v-for="(phoneNumber, index) in phoneNumbers"
                 :key="index">
            <v-col style="max-width:180px;" class="text-right">
              <phone-type v-model="phoneNumber.phone_type" @change="updateNumber(phoneNumber, index)"></phone-type>
            </v-col>

            <v-col class="pl-6">
              <v-text-field v-model="phoneNumber.phone_number"
                            outlined
                            ref="phoneNumber"
                            :rules="[rules.required, rules.min, rules.max, rules.phone_number]"
                            @change="updateNumber(phoneNumber, index)"
                            :placeholder="$t('phone_number')"
                            :append-icon="phoneNumber.is_main_number ? undefined : 'icon-trash'"
                            @click:append="deletePhoneNumber(index, phoneNumber.id)"
              ></v-text-field>
            </v-col>
            <v-col style="max-width:40px;">

              <v-tooltip bottom>
                <template v-slot:activator="{ on }">
                  <v-simple-checkbox v-model="phoneNumber.is_main_number"
                                     @click.prevent="updateMainNumber(phoneNumber)"
                                     :disabled="phoneNumber.id === -1 || phoneNumber.is_main_number"
                                     v-on="on"
                                     :ripple="false"></v-simple-checkbox>
                </template>
                <span>{{ $t('is_main_number') }}</span>
              </v-tooltip>

            </v-col>
          </v-row>
          <v-row dense class="align-center row--br">
            <v-col style="max-width:180px;" class="text-right"></v-col>
            <v-col class="pl-6">
              <v-btn @click="addPhoneNumber">
                <div class="iconSq iconSq--sm iconSq--border gradient mr-4 ml-n4">
                  <v-icon color="white" size="22">icon-plus</v-icon>
                </div>
                <div class="flex-grow-1 text-left">{{ $t('addNumber') }}</div>
              </v-btn>
            </v-col>
          </v-row>

          <!--          <v-row dense class="align-center row&#45;&#45;br">-->
          <!--            <v-col style="max-width:180px;" class="text-right">&nbsp;</v-col>-->
          <!--            <v-col class="pl-6">-->
          <!--              <div class="d-flex align-center justify-space-between">-->
          <!--                <h4 class="dark-red&#45;&#45;text ma-0">{{ $t('email') }}</h4>-->
          <!--                <div class="buttonContainer justify-end"></div>-->
          <!--              </div>-->
          <!--            </v-col>-->
          <!--          </v-row>-->
          <!--          <v-row dense class="align-center row&#45;&#45;br">-->
          <!--            <v-col style="max-width:180px;" class="text-right"><label>{{ $t('loginEmail') }}</label></v-col>-->
          <!--            <v-col class="pl-6">-->
          <!--              <v-text-field outlined hide-details :placeholder="$t('loginEmail')"-->
          <!--                            :rules="[rules.required]"-->
          <!--                            v-model="user.main_member.email"></v-text-field>-->
          <!--            </v-col>-->
          <!--          </v-row>-->
          <v-row dense class="align-center row--br">
            <v-col style="max-width:180px;" class="text-right">&nbsp;</v-col>
            <v-col class="pl-6">
              <div class="d-flex align-center justify-space-between">
                <h4 class="dark-red--text ma-0">{{ $t('mandatoryDocuments') }}</h4>
                <div class="buttonContainer justify-end"></div>
              </div>
            </v-col>
          </v-row>
          <v-row dense class="align-center row--br">
            <v-col style="max-width:180px;" class="text-right">
              <label style="white-space: pre;">{{ $t('passport') }}</label>
            </v-col>
            <v-col class="pl-6">
              <FileUpload :type="documentTypes.PASSPORT" :member-id="user.main_member.id"
                          :document="passport"></FileUpload>
            </v-col>
          </v-row>
          <v-row dense class="align-center row--br">
            <v-col style="max-width:180px;" class="text-right"><label>{{ $t('residencePermit') }}</label></v-col>
            <v-col class="pl-6">
              <FileUpload :type="documentTypes.RESIDENCE_PERMIT" :member-id="user.main_member.id"
                          :document="residencePermit"></FileUpload>
            </v-col>
          </v-row>
          <!--          <v-row dense class="align-center row&#45;&#45;br">-->
          <!--            <v-col style="max-width:180px;" class="text-right"><label>{{ $t('registrationForm') }}</label></v-col>-->
          <!--            <v-col class="pl-6">-->
          <!--              <FileUpload :type="documentTypes.REGISTRATION_FORM" :member-id="user.main_member.id"-->
          <!--                          :document="registrationForm"></FileUpload>-->
          <!--            </v-col>-->
          <!--          </v-row>-->
          <v-row dense class="align-center row--br">
            <v-col style="max-width:180px;" class="text-right">&nbsp;</v-col>
            <v-col class="pl-6">
              <div class="d-flex align-center justify-space-between">
                <h4 class="dark-red--text ma-0">{{ $t('otherDocuments') }}</h4>
                <div class="buttonContainer justify-end"></div>
              </div>
            </v-col>
          </v-row>
          <v-row dense class="align-center row--br" v-for="document in otherDocuments" :key="document.id">
            <v-col style="max-width:180px;" class="text-right"><label>{{ $t('otherDocuments') }}</label></v-col>
            <v-col class="pl-6">
              <FileUpload :type="documentTypes.OTHER" :member-id="user.main_member.id" ref="documentOther"
                          :document="document"></FileUpload>
            </v-col>
          </v-row>
          <v-row dense class="align-center row--br" v-if="newDocumentShown">
            <v-col style="max-width:180px;" class="text-right"><label>{{ $t('addDocuments') }}</label></v-col>
            <v-col class="pl-6">
              <FileUpload :type="documentTypes.OTHER" :member-id="user.main_member.id"
                          @fileUploaded="newDocumentShown = false;"></FileUpload>
            </v-col>
          </v-row>
          <v-row dense class="align-center row--br">
            <v-col style="max-width:180px;" class="text-right">&nbsp;</v-col>
            <v-col class="pl-6">
              <v-btn @click="addDocument">
                <div class="iconSq iconSq--sm iconSq--border gradient mr-4 ml-n4">
                  <v-icon color="white" size="22">icon-plus</v-icon>
                </div>
                <div class="flex-grow-1 text-left">{{ $t('upload') }}</div>
              </v-btn>
            </v-col>
          </v-row>

        </div>

        <div class="gyC" v-if="displayBurialPlace">
          <div style="max-width:760px;">

            <v-row dense class="align-center row--br">
              <v-col style="max-width:180px;" class="text-right">&nbsp;</v-col>
              <v-col class="pl-6">
                <div class="d-flex align-center justify-space-between">
                  <h4 class="dark-white--text ma-0">{{ $t('desiredBurialPlace') }}</h4>
                </div>
              </v-col>
            </v-row>
            <v-form ref="burialPlace">

              <v-row dense class="align-center row--br">
                <v-col style="max-width:180px;" class="text-right"><label>{{ $t('cemetery') }}</label></v-col>
                <v-col class="pl-6">
                  <v-text-field outlined :placeholder="$t('cemetery')"
                                @click="burialPlaceDirty=true"
                                v-model="burialPlace.graveyard" :rules="[rules.required]"></v-text-field>
                </v-col>
              </v-row>
              <!--            <v-row dense class="align-center row&#45;&#45;br">-->
              <!--              <v-col style="max-width:180px;" class="text-right"><label>{{ $t('group') }}</label></v-col>-->
              <!--              <v-col class="pl-6">-->
              <!--                <v-text-field outlined hide-details :placeholder="$t('group')"-->
              <!--                              v-model="burialPlace.group"></v-text-field>-->
              <!--              </v-col>-->
              <!--            </v-row>-->
              <v-row dense class="align-center row--br">
                <v-col style="max-width:180px;" class="text-right"><label>{{ $t('row') }}</label></v-col>
                <v-col class="pl-6">
                  <v-text-field outlined hide-details :placeholder="$t('row')"
                                @click="burialPlaceDirty=true"
                                v-model="burialPlace.grave_row"></v-text-field>
                </v-col>
              </v-row>
              <v-row dense class="align-center row--br">
                <v-col style="max-width:180px;" class="text-right"><label>{{ $t('number') }}</label></v-col>
                <v-col class="pl-6">
                  <v-text-field outlined hide-details :placeholder="$t('number')"
                                @click="burialPlaceDirty=true"
                                v-model="burialPlace.grave_number"></v-text-field>
                </v-col>
              </v-row>
              <!--            <v-row dense class="align-center row&#45;&#45;br">-->
              <!--              <v-col style="max-width:180px;" class="text-right"><label>{{ $t('graveUser') }}</label></v-col>-->
              <!--              <v-col class="pl-6">-->
              <!--                <v-text-field outlined hide-details :placeholder="$t('graveUser')"-->
              <!--                              v-model="burialPlace.graveUser"></v-text-field>-->
              <!--              </v-col>-->
              <!--            </v-row>-->
              <v-row dense class="align-center row--br">
                <v-col style="max-width:180px;" class="text-right"><label>{{ $t('postal_code') }}</label></v-col>
                <v-col class="pl-6">
                  <v-text-field outlined hide-details :placeholder="$t('postal_code')"
                                @click="burialPlaceDirty=true"
                                v-model="burialPlace.postal_code"></v-text-field>
                </v-col>
              </v-row>
              <v-row dense class="align-center row--br">
                <v-col style="max-width:180px;" class="text-right"><label>{{ $t('state') }}</label></v-col>
                <v-col class="pl-6">
                  <v-text-field outlined hide-details :placeholder="$t('state')"
                                @click="burialPlaceDirty=true"
                                v-model="burialPlace.state"></v-text-field>
                </v-col>
              </v-row>
              <v-row dense class="align-center row--br">
                <v-col style="max-width:180px;" class="text-right"><label>{{ $t('city') }}</label></v-col>
                <v-col class="pl-6">
                  <v-text-field outlined :placeholder="$t('city')"
                                @click="burialPlaceDirty=true"
                                v-model="burialPlace.city" :rules="[rules.required]"></v-text-field>
                </v-col>
              </v-row>
              <v-row dense class="align-center row--br">
                <v-col style="max-width:180px;" class="text-right"><label>{{ $t('street') }}</label></v-col>
                <v-col class="pl-6">
                  <v-text-field outlined hide-details :placeholder="$t('street')"
                                @click="burialPlaceDirty=true"
                                v-model="burialPlace.street"></v-text-field>
                </v-col>
              </v-row>
              <v-row dense class="align-center row--br">
                <v-col style="max-width:180px;" class="text-right"><label>{{ $t('country') }}</label></v-col>
                <v-col class="pl-6">
                  <v-select
                    v-model="burialPlace.country_id"
                    :rules="[rules.required]"
                    outlined
                    :items="countries"
                    item-text="text"
                    item-value="id"
                    @click="burialPlaceDirty=true"
                    attach
                    :placeholder="$t('country')"
                  ></v-select>
                </v-col>
              </v-row>
            </v-form>
            <v-row dense class="align-center row--br">
              <v-col style="max-width:180px;" class="text-right"></v-col>
              <v-col class="pl-6">
                <v-btn @click="updateGraveField">
                  <div v-if="burialPlace.id === -1" class="flex-grow-1 text-center">{{ $t('save') }}</div>
                  <div v-else class="flex-grow-1 text-center">{{ $t('update') }}</div>
                </v-btn>
              </v-col>
            </v-row>

            <v-row dense class="align-center row--br">
              <v-col style="max-width:180px;" class="text-right">&nbsp;</v-col>
              <v-col class="pl-6">
                <div class="d-flex align-center justify-space-between">
                  <h4 class="dark-white--text ma-0">{{ $t('documentsOfDeath') }}</h4>
                </div>
              </v-col>
            </v-row>
            <v-row dense class="align-center row--br">
              <v-col style="max-width:180px;" class="text-right"><label>{{ $t('birthCertificate') }}</label></v-col>
              <v-col class="pl-6">
                <FileUpload :type="documentTypes.BIRTH_CERTIFICATE" :member-id="user.main_member.id"
                            :document="birthCertificate"></FileUpload>
              </v-col>
            </v-row>
            <v-row dense class="align-center row--br">
              <v-col style="max-width:180px;" class="text-right"><label>{{ $t('proofOfCitizenship') }}</label></v-col>
              <v-col class="pl-6">
                <FileUpload :type="documentTypes.PROOF_OF_CITIZENSHIP" :member-id="user.main_member.id"
                            :document="proofOfCitizenship"></FileUpload>
              </v-col>
            </v-row>
            <v-row dense class="align-center row--br">
              <v-col style="max-width:180px;" class="text-right"><label>
                {{ $t('internationalMarriageCertificate') }}</label>
              </v-col>
              <v-col class="pl-6">
                <FileUpload :type="documentTypes.INTERNATIONAL_MARRIAGE_CERTIFICATE" :member-id="user.main_member.id"
                            :document="marriageCertificate"></FileUpload>
              </v-col>
            </v-row>
            <v-row dense class="align-center row--br">
              <v-col style="max-width:180px;" class="text-right"><label>{{ $t('divorceDecree') }}</label></v-col>
              <v-col class="pl-6">
                <FileUpload :type="documentTypes.FINAL_DIVORCE_DECREE" :member-id="user.main_member.id"
                            :document="divorceDecree"></FileUpload>
              </v-col>
            </v-row>
            <v-row dense class="align-center row--br">
              <v-col style="max-width:180px;" class="text-right"><label>{{ $t('deathCertificateSpouse') }}</label>
              </v-col>
              <v-col class="pl-6">
                <FileUpload :type="documentTypes.DECEASE_SPOUSE_CERTIFICATE" :member-id="user.main_member.id"
                            :document="deceaseSpouseCertificate"></FileUpload>
              </v-col>
            </v-row>
            <v-row dense class="align-center row--br" v-for="document in otherDeathDocuments" :key="document.id">
              <v-col style="max-width:180px;" class="text-right"><label>{{ $t('otherDocuments') }}</label></v-col>
              <v-col class="pl-6">
                <FileUpload :type="documentTypes.DEATH_OTHER" :member-id="user.main_member.id" ref="documentOther"
                            :document="document"></FileUpload>
              </v-col>
            </v-row>


            <v-row dense class="align-center row--br" v-if="newOtherDeathDocumentShown">
              <v-col style="max-width:180px;" class="text-right"><label>{{ $t('addDocuments') }}</label></v-col>
              <v-col class="pl-6">
                <FileUpload :type="documentTypes.DEATH_OTHER" :member-id="user.main_member.id"
                            @fileUploaded="newOtherDeathDocumentShown = false;"></FileUpload>
              </v-col>
            </v-row>
            <v-row dense class="align-center row--br">
              <v-col style="max-width:180px;" class="text-right">&nbsp;</v-col>
              <v-col class="pl-6">
                <v-btn @click="addOtherDeathDocument">
                  <div class="iconSq iconSq--sm iconSq--border gradient mr-4 ml-n4">
                    <v-icon color="white" size="22">icon-plus</v-icon>
                  </div>
                  <div class="flex-grow-1 text-left">{{ $t('upload') }}</div>
                </v-btn>
              </v-col>
            </v-row>
          </div>
        </div>

      </v-form>

      <!-------------------------->
    </div>
    <div class="buttonContainer justify-space-between mt-3">
      <v-btn to="/news">
        <div class="iconSq iconSq--sm iconSq--border gradient">
          <v-icon color="white" size="22">icon-chevron-left</v-icon>
        </div>
        <div class="flex-grow-1 text-center">{{ $t('navigationButtons.backToNews') }}</div>
      </v-btn>
      <v-btn @click.prevent="updateMember">
        <div class="flex-grow-1 text-center">{{ $t('navigationButtons.nextToHousehold') }}</div>
        <div class="iconSq iconSq--sm iconSq--border gradient">
          <v-icon color="white" size="22">icon-chevron-right</v-icon>
        </div>
      </v-btn>
    </div>
    <Manual :step="0" :visible="showManual" @toggle="showManual = !showManual" v-if="!$existingMember"></Manual>
    <confirm ref="confirm"></confirm>
  </Dashboard>
</template>
<script>
import Dashboard from '@/layouts/Dashboard.vue';
import Manual from '@/components/Manual.vue';
import FileUpload from '@/components/FileUpload.vue';
import Confirm from "../../components/Dialogs/Confirm";
import Gender from "../../components/Fields/Gender";
import CivilStatus from "../../components/Fields/CivilStatus";
import userService from "../../services/userService";
import addressService from "../../services/addressService";
import phoneNumberService from "../../services/phoneNumberService";
import eligibilityService from "../../services/eligibilityService";
import {documentTypes} from "../../enumerations/documentTypes";
import burialPlace from "../../interfaces/burialPlace";
import PhoneType from "../../components/Fields/PhoneType";
import moment from "moment";
import DatePicker from "../../components/Fields/DatePicker";

export default {
  name: 'MainMember',
  components: {
    DatePicker,
    PhoneType,
    Confirm,
    Dashboard,
    Manual,
    FileUpload,
    Gender,
    CivilStatus
  },
  data: () => ({
    documentTypes,
    rules: {
      checkboxRequired: value => value || 'Required',
      required: value => !!value || 'Required.',
      phone_number: value => !value || /^\+?[0-9]+$/.test(value) || 'Invalid phone number',
      min: v => v.length >= 6 || 'Min 6 characters',
      max: v => v.length <= 15 || 'Max 15 characters',
      number: value => /^[0-9]*$/.test(value) || 'Only numbers alowed',
      // turkishIdentityNumber: value => this.validateTurkishIdentityNumber(value),
      // turkishIdentityNumber: value => {
      //   this.validateTurkishIdentityNumber(value);
      //   if (!/^[1-9]\d{10}$/.test(value)) {
      //     return '11 digits and first should be non-zero'
      //   }
      //   return true;
      // }
    },
    newDocumentShown: false,
    newOtherDeathDocumentShown: false,
    date: null,
    datePicker1: false,
    showManual: false,
    showConfirmWindow: false,
    fromSave: false,
    modelConfig: {
      type: 'string',
      mask: 'DD.MM.YYYY', // Uses 'iso' if missing
    },
    today: new Date().toJSON(),
    user: null,
    burialPlaceDirty: false,
    burialPlace: null,
    civilStatuses: []
  }),
  computed: {
    countries() {
      return this.$store.getters['countries/translatedCountries'];
    },
    storeUser() {
      return JSON.parse(JSON.stringify(this.$store.getters['user/user']));
    },
    otherDocuments() {
      return this.storeUser.main_member.documents.filter(document => document.type === documentTypes.OTHER);
    },
    passport() {
      return this.storeUser.main_member.documents.find(document => document.type === documentTypes.PASSPORT);
    },
    birthCertificate() {
      return this.storeUser.main_member.documents.find(document => document.type === documentTypes.BIRTH_CERTIFICATE);
    },
    proofOfCitizenship() {
      return this.storeUser.main_member.documents.find(document => document.type === documentTypes.PROOF_OF_CITIZENSHIP);
    },
    marriageCertificate() {
      return this.storeUser.main_member.documents.find(document => document.type === documentTypes.INTERNATIONAL_MARRIAGE_CERTIFICATE);
    },
    divorceDecree() {
      return this.storeUser.main_member.documents.find(document => document.type === documentTypes.FINAL_DIVORCE_DECREE);
    },
    deceaseSpouseCertificate() {
      return this.storeUser.main_member.documents.find(document => document.type === documentTypes.DECEASE_SPOUSE_CERTIFICATE);
    },
    otherDeathDocuments() {
      return this.storeUser.main_member.documents.filter(document => document.type === documentTypes.DEATH_OTHER);
    },
    residencePermit() {
      return this.storeUser.main_member.documents.find(document => document.type === documentTypes.RESIDENCE_PERMIT);
    },
    registrationForm() {
      return this.storeUser.main_member.documents.find(document => document.type === documentTypes.REGISTRATION_FORM);
    },
    phoneNumbers() {
      return this.sortPhoneNumbers();
    },
    displayBurialPlace() {
      return this.user.main_member.status === 'active' || this.user.main_member.status === 'waiting';
    }
  },
  methods: {
    getEligibilityRelations() {
      eligibilityService.index()
        .then(res => {
          this.civilStatuses = res.civil_statuses.map(item => {
            return {
              value: item.name,
              id: item.id,
            }
          });
        });
    },
    addPhoneNumber() {
      if (this.user.main_member.phone_numbers.find(item => item.id === -1)) {
        return;
      }

      this.$store.dispatch('user/addMainMemberPhoneNumber');
    },
    updateMember() {
      if (!this.$refs.mainMember.validate()) {
        this.$store.dispatch('alert/toggleShow', true);
        this.$store.dispatch('alert/setMessage', this.$t('missing_mandatory_fields'));
        return;
      }

      let data = {
        first_name: this.user.main_member.first_name,
        last_name: this.user.main_member.last_name,
        date_of_birth: moment(this.user.main_member.date_of_birth).format('DD.MM.YYYY'),
        social_insurance_number: this.user.main_member.social_insurance_number,
        turkish_id_no: this.user.main_member.turkish_id_no,
        citizenship_id: this.user.main_member.citizenship_id,
        place_of_birth: this.user.main_member.place_of_birth,
        civil_status: this.user.main_member.civil_status,
        gender: this.user.main_member.gender,
        father_name: this.user.main_member.father_name,
        mother_name: this.user.main_member.mother_name,
        permanent_residence: this.user.main_member.permanent_residence,
      };

      let _this = this;
      userService.update(this.user.main_member.id, data).then(async () => {
        this.fromSave = true;
        userService.verify().then(() => {
          if ((_this.burialPlace.id === -1 || this.burialPlaceDirty) && this.$existingMember) {
            _this.$refs.confirm.confirm(this.$t('missing_burial_place_alert'))
              .then(() => {
                _this.burialPlace = JSON.parse(JSON.stringify(burialPlace));
                _this.$router.push({name: 'household'});
              }).catch(() => {
              return;
            });
            return;
          }
          this.$router.push({name: 'household'});
        });
      });
    },
    updateMainNumber(number) {
      if (!number.is_main_number) {
        return;
      }

      phoneNumberService.update(number.id, number).then(() => {
        userService.verify();
      });
    },
    updateNumber(number, index) {
      if (!this.$refs.phoneNumber[index].validate()) {
        return;
      }
      if (number.phone_number === '') {
        return;
      }

      if (number.id === -1) {
        phoneNumberService.store(this.user.main_member.id, number).then(() => {
          userService.verify();
        });
        return;
      }

      phoneNumberService.update(number.id, number);
    },
    deletePhoneNumber(index, phoneNumberId) {
      this.confirm(this.$t('confirmAction'))
        .then(() => {
          if (phoneNumberId === -1) {
            userService.verify();
            return;
          }
          phoneNumberService.delete(phoneNumberId).then(() => {
            this.user.main_member.phone_numbers.splice(index, 1);
            userService.verify();
          });
        })
        .catch(() => {
          return;
        });
    },
    addDocument() {
      this.newDocumentShown = true;
    },
    addOtherDeathDocument() {
      this.newOtherDeathDocumentShown = true;
    },
    confirm(message) {
      return this.$refs.confirm.confirm(message);
    },
    async updateGraveField() {
      if (!this.$refs.burialPlace.validate()) {
        return false;
      }

      if (this.burialPlace.id === -1) {
        addressService.store(this.user.main_member.id, this.burialPlace)
          .then(res => {
            this.burialPlace.id = res.data.id;
            this.burialPlaceDirty = false;
            userService.verify();
          });
        return true;
      }

      addressService.update(this.burialPlace.id, this.burialPlace)
        .then(() => {
          this.burialPlaceDirty = false;
          userService.verify();
        });
      return true;
    },
    validateTurkishIdentityNumber(value) {
      if (value === null || value === '') {
        return true;
      }

      value = String(value);
      if (!(/^[1-9]\d{10}$/).test(value)) return false;

      console.log(value);
      const digits = value.split('');
      // store last 2 digits (10th and 11th) which are actually used for validation
      const d10 = Number(digits[9]);
      const d11 = Number(digits[10]);
      // we'll also need the sum of first 10 digits for validation
      let sumOf10 = 0;
      let evens = 0;
      let odds = 0;

      digits.forEach((d, index) => {
        d = Number(d);
        if (index < 10) sumOf10 += d;
        if (index < 9) {
          if ((index + 1) % 2 === 0) {
            evens += d;
          } else {
            odds += d;
          }
        }
      });

      // check if the unit-digit of the sum of first 10 digits equals to the 11th digit.
      if (sumOf10 % 10 !== d11) return false;

      // check if unit-digit of the sum of odds * 7 and evens * 9 is equal to 10th digit.
      if (((odds * 7) + (evens * 9)) % 10 !== d10) return false;

      // check if unit-digit of the sum of odds * 8 is equal to 11th digit.
      if ((odds * 8) % 10 !== d11) return false;

      return true;
    },
    dateOfBirthInput(val) {
      if (val.length === 4 || val.length === 7) {
        this.user.main_member.date_of_birth = val + '-';
      }
      if (val.length > 10) {
        this.user.main_member.date_of_birth = val.slice(0, -2);
      }
      return false;
    },
    validateDateOfBirth(value) {
      if (value === null || value === '' || value.length < 10) {
        return false;
      }

      if (moment(value, 'YYYY-MM-DD').isBefore(moment())) {
        return true;
      }

      return false;
    },
    sortPhoneNumbers() {
      return this.storeUser.main_member.phone_numbers.sort((a, b) => {
        if (a.id === -1) {
          return 1;
        }
        if (a.id < b.id) {
          return -1;
        }
        return 1;
      });
    }
  },
  mounted() {
    this.$store.dispatch('user/setStep', 1);
    this.user = this.storeUser;
    this.burialPlace = this.$store.getters['user/burialPlace']
      ? JSON.parse(JSON.stringify(this.$store.getters['user/burialPlace']))
      : JSON.parse(JSON.stringify(burialPlace));

    this.getEligibilityRelations();
  },
  beforeRouteLeave(to, from, next) {
    if (this.fromSave || this.$existingMember) {
      next();
      return;
    }

    if (this.user === this.$store.getters['user/user']) {
      next();
      return;
    }

    this.confirm(this.$t('confirmUnsavedDataProceed'))
      .then(next)
      .catch(() => {
        next(false);
      });
  }
};
</script>
