import Vue from 'vue';
import App from './App.vue';
import router from './router';
import vuetify from './plugins/vuetify';
import store from './plugins/store';
import {pushInterceptor} from './plugins/axios';
import i18n from './plugins/i18n';
import {documentTypes} from "./enumerations/documentTypes";

import './assets/scss/all.scss';
import countryService from "./services/countryService";

Vue.config.productionTip = false;
countryService.index();

pushInterceptor(Vue.axios, store);

Vue.prototype.$existingMember = false;
Vue.prototype.documentTypes = documentTypes;

new Vue({
    router,
    store,
    i18n,
    vuetify,
    render: (h) => h(App),
}).$mount('#app');
