<template>
  <v-select
    :items="items"
    v-bind:value="value"
    :item-text="getLabel"
    :item-value="itemValue"
    :placeholder="placeholder"
    :menu-props="{ bottom: true, offsetY: true, contentClass:'v-select-dropdown' }"
    :disabled="disabled"
    v-on:change="updateValue($event)"
    hide-details
    attach
    :rules="rules"
    outlined>
  </v-select>
</template>

<script>

export default {
  name: "SubstituteType",
  data: () => ({}),
  props: {
    value: {
      type: String,
      default: null
    },
    disabled: {
      type: Boolean,
      default: false
    },
    items: {
      type: Array,
      default: () => {
        return [];
      }
    },
    placeholder: {
      type: String,
      default: null
    },
    labelPrefix: {
      type: String,
      default: null
    },
    rules: {
      type: Array,
      default: () => {
        return [];
      }
    },
    itemValue: {
      type: String,
      default: 'value'
    }
  },
  methods: {
    getLabel(item) {
      return this.$t(`${this.labelPrefix}.${item.value}`);
    },
    updateValue: function (value) {
      this.$emit('input', value);
      this.$emit('change', value);
    }
  }
}
</script>

<style scoped>

</style>
