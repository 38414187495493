import {addressTypes} from "../enumerations/addressTypes";

const burialPlace = {
    id: -1,
    graveyard: '',
    grave_row: '',
    grave_number: '',
    postal_code: '',
    state: '',
    city: '',
    street: '',
    country_id: null,
    type: addressTypes.BURIAL_PLACE
}

export default burialPlace;
