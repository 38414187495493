const genders = [
    {
        value: 'M',
        label: 'male',
    },
    {
        value: 'F',
        label: 'female',
    }
];

export default genders;
