<template>
  <div>
    <v-dialog
      :value="show"
      :max-width="860"
      persistent
      content-class="v-dialog--alert"
    >
      <v-card>
        <v-card-text>
          <h2 class="dark-red--text">{{ $t('registerInstructionWindow.title') }}</h2>
          <p>{{ $t('registerInstructionWindow.content') }}</p>
        </v-card-text>
        <v-card-actions>
          <div class="buttonContainer justify-center">
            <v-btn @click="close">{{ $t('registerInstructionWindow.button') }}</v-btn>
          </div>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>

export default {
  name: "RegisterInstructions",
  comments: {},
  computed: {
    show() {
      return this.$store.getters['registerInstruction/show'];
    }
  },
  methods: {
    close() {
      this.$store.commit('registerInstruction/toggleShow', false);
    }
  }
}
</script>

<style scoped>

</style>
