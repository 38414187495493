import Vue from 'vue';

export default {
    async termsOfUse() {
        const response = await Vue.axios.get('/api/v1/download-file/terms_of_use', {responseType: 'blob'});
        return response.data;
    },
    async dataProtection() {
        const response = await Vue.axios.get('/api/v1/download-file/data_protection', {responseType: 'blob'});
        return response.data;
    },

}
