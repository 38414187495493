import Vue from 'vue'
import axios from 'axios'
import VueAxios from 'vue-axios'

Vue.use(VueAxios, axios)

Vue.axios.defaults.baseURL = window.VUE_APP_API_URL || process.env.VUE_APP_API_URL || "https://atib-devl.7twenty.at/";

export function pushInterceptor(axios, store) {
    axios.interceptors.request.use((config) => {
        store.dispatch('loader/toggleShow', true);
        config.headers.Accept = "application/json";
        config.withCredentials = true;
        return config;
    }, error => {
        store.dispatch('alert/toggleShow', true);
        store.dispatch('alert/setMessage', error.response.data.message || error.response.statusText);
        store.dispatch('loader/toggleShow', false);
        return Promise.reject(error);
    });

    axios.interceptors.response.use(response => {
        store.dispatch('loader/toggleShow', false);
        return response;
    }, error => {
        if (error.response.status === 401) {
            if (store.getters['user/isLoggedIn']) {
                store.dispatch('user/logout');
            }
            store.dispatch('loader/toggleShow', false);
            return Promise.reject(error);
        }

        let message = error.response.data.message
            || error.response.data
            || error.response.data.messages.toString()
            || error.response.statusText;

        if (typeof message === 'object') {
            if (message.messages !== undefined) {
                message = message.messages.join('<br>')
            }
        }

        store.dispatch('alert/toggleShow', true);
        store.dispatch('alert/setMessage', message);
        store.dispatch('loader/toggleShow', false);
        return Promise.reject(error);
    });
}
